import { computed, Ref, ref } from 'vue';

import { ListOptions } from '@/services/api/listOptions';
import {
  ArticleList,
  ArticleListItem,
} from '@/services/api/modules/article.types';
import { SearchQuery } from '@/services/api/modules/search';

interface Module {
  search(item: SearchQuery, options: ListOptions): Promise<ArticleList>;
  searchesRes?(searches: Searches, options: ListOptions): Promise<ArticleList>;
}

export interface Searches {
  context: string;
  itemId: string;
  searchId: string;
}

interface UseSearch {
  input: Ref<HTMLInputElement | undefined>;
  search: Ref<string>;
  showSearch: Ref<boolean>;
  searchLoading: Ref<boolean>;
  searchEmpty: Ref<boolean>;
  searchItems: Ref<ArticleListItem[]>;
  searchesObject: Ref<Searches>;
  toggleSearch(): void;
  openSearch(): void;
  closeSearch(): void;
  handleSearch(): void;
  fetchNext(): void;
  handleKey(event: KeyboardEvent): void;
  resetSearch(): void;
  resetField(field: string): void;
}

export default (id: string, module: Module): UseSearch => {
  const input = ref();
  const search = ref('');
  const showSearch = ref(false);
  const searchLoading = ref(false);
  const searchFetched = ref(false);
  const searchFields = new Map();
  const currentItemId = ref();
  const searchItems = ref<ArticleListItem[]>([]);
  const searchesObject = ref<Searches>({
    context: '',
    itemId: '',
    searchId: '',
  });

  const toggleSearch = () => {
    if (showSearch.value) {
      if (searchFields.size == 0) {
        showSearch.value = !showSearch.value;
      } else {
        search.value = '';
        handleSearch();
      }
    } else {
      showSearch.value = !showSearch.value;
    }
    if (!showSearch.value) {
      search.value = '';
      searchItems.value = [];
      searchFields.clear();
      searchLoading.value = false;
      searchFetched.value = false;
    } else {
      setTimeout(input.value?.focus);
    }
  };

  const openSearch = () => {
    showSearch.value = true;
  };

  const closeSearch = () => {
    showSearch.value = false;
  };

  const resetSearch = () => {
    search.value = '';
  };

  const resetField = (field: string) => {
    searchFields.delete(field);
    if (searchFields.get('searches') != null) {
      handleSearch({
        context: 'searches',
        searchId: searchFields.get('searches'),
        itemId: currentItemId.value,
      });
    } else if (search.value) {
      handleSearch();
    } else if (searchFields.get('regions') != null) {
      handleSearch({
        context: 'regions',
        searchId: searchFields.get('regions'),
        itemId: currentItemId.value,
      });
    } else {
      showSearch.value = false;
    }
  };

  const handleSearch = async (
    searches = { context: '', itemId: '', searchId: '' },
  ) => {
    if (searches.context != '') {
      searchFields.set(searches.context, searches.searchId);
    } else {
      if (searches.itemId == '') {
        searches.itemId = currentItemId.value;
      }
      if (
        searchFields.get('searches') != null &&
        searchFields.get('searches') != ''
      ) {
        searches.context = 'searches';
        searches.searchId = searchFields.get('searches');
      } else if (!search.value) {
        if (
          searchFields.get('regions') != null &&
          searchFields.get('regions') != ''
        ) {
          searches.context = 'regions';
          searches.searchId = searchFields.get('regions');
        }
      }
    }
    currentItemId.value = searches.itemId;
    if (
      search.value &&
      (searchFields.get('searches') == null ||
        searchFields.get('searches') == '')
    ) {
      searchLoading.value = true;
      const data = await module.search(
        { id, queryText: search.value },
        { count: 30, regionId: searchFields.get('regions') },
      );
      searchItems.value = data.items;

      searchItems.value = data.items;
      searchLoading.value = false;
      searchFetched.value = true;
    } else if (searches.context != '' && module.searchesRes != null) {
      if (
        searches.context != 'searches' &&
        searchFields.get('searches') != null &&
        searchFields.get('searches') != ''
      ) {
        searches.context = 'searches';
        searches.searchId = searchFields.get('searches');
      }
      searchesObject.value = searches;
      searchLoading.value = true;
      const data = await module.searchesRes(searches, {
        count: 30,
        regionId: searchFields.get('regions'),
        filterQuery: search.value,
      });
      searchItems.value = data.items;
      searchItems.value = data.items;
      searchLoading.value = false;
      searchFetched.value = true;
    }
  };

  const fetchNext = async () => {
    if (search.value) {
      searchLoading.value = true;
      const [lastItem] = searchItems.value.slice(-1);

      const data = await module.search(
        { id, queryText: search.value },
        { count: 30, fromItem: lastItem?.id },
      );

      searchItems.value = [...searchItems.value, ...data.items];

      searchLoading.value = false;
      searchFetched.value = true;
    } else if (
      searchesObject.value.context != '' &&
      module.searchesRes != null
    ) {
      searchLoading.value = true;
      const [lastItem] = searchItems.value.slice(-1);
      const experts = searchesObject.value.context === 'regions';

      const data = await module.searchesRes(
        {
          context: searchesObject.value.context,
          itemId: searchesObject.value.itemId,
          searchId: searchesObject.value.searchId,
        },
        {
          count: 30,
          fromItem: !experts ? lastItem?.id : undefined,
          offset: experts ? searchItems.value.length : undefined,
        },
      );

      searchItems.value = [...searchItems.value, ...data.items];

      searchLoading.value = false;
      searchFetched.value = true;
    }
  };

  const handleKey = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (showSearch.value === false) {
        toggleSearch();
      }
      handleSearch();
    }
  };

  const searchEmpty = computed(
    () =>
      searchFetched.value && !searchLoading.value && !searchItems.value.length,
  );

  return {
    input,
    search,
    showSearch,
    searchLoading,
    searchEmpty,
    searchItems,
    searchesObject,
    toggleSearch,
    openSearch,
    closeSearch,
    handleSearch,
    fetchNext,
    handleKey,
    resetSearch,
    resetField,
  };
};
