import { pick } from 'lodash';

import { OBJECT_KINDS, OBJECT_SCOPES } from '@/config/enums';

import http from '../http';

export interface Obj {
  id: string;
  kind: string;
  object: {
    [key in string]: string;
  };
  scope: string;
}

export interface UpdateObject {
  id?: string;
  kind: OBJECT_KINDS;
  scope: OBJECT_SCOPES;
  object: {
    [key in string]: string;
  };
}

const index = (): Promise<Obj[]> => {
  return http.get('/ui/objects');
};

const store = (item: UpdateObject): Promise<undefined> => {
  return http.post(`/ui/objects`, pick(item, ['kind', 'scope', 'object']));
};

const update = (item: UpdateObject): Promise<undefined> => {
  return http.put(
    `/ui/objects/${item.id}`,
    pick(item, ['kind', 'scope', 'object']),
  );
};

export default { index, store, update };
