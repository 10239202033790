
import { defineComponent, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';

import { Control, SubItem } from '@/config/navigation/main';
import useFeatures from '@/hooks/useFeatures';

import Controls from './Controls.vue';

interface OpenState {
  [key: string]: boolean;
}

export default defineComponent({
  components: {
    Controls,
  },

  props: {
    controls: {
      type: Object as () => Control[],
      required: false,
    },
    items: {
      type: Array as () => SubItem[],
      required: true,
    },
    wide: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const route = useRoute();
    const openState: Ref<OpenState> = ref({});

    const getIcon = (id: string) =>
      openState.value[id] ? 'expand_less' : 'expand_more';

    const toggleOpen = (id: string) => {
      openState.value[id] = !openState.value[id];
    };

    const isActive = (id: string, index: number) => {
      const type = props.items[index].to.name;

      if (type === 'calendar') {
        const children = props.items[index].children;

        if (children) {
          return children.some((c) => {
            return c.id === route.params.resourceId;
          });
        }
      }

      return route.params.id == id;
    };

    const isActiveChild = (id: string, index: number) => {
      const type = props.items[index].to.name;

      if (type === 'calendar') {
        return route.params.resourceId === id;
      }

      return false;
    };

    return {
      openState,
      getIcon,
      toggleOpen,
      isActive,
      isActiveChild,
      ...useFeatures(),
    };
  },
});
