
import { format } from 'date-fns';
import { computed, defineComponent, Ref, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import {
  DISPLAY_TYPES,
  MESSAGE_TYPES,
  SECTION_TYPES,
  SORT_TYPES,
} from '@/config/enums';
import { SEARCH_ARTICLE_AI } from '@/config/routes';
import useFeatures from '@/hooks/useFeatures';
import useMessage from '@/hooks/useMessage';
import useValidation from '@/hooks/useValidation';
import Facet from '@/modules/aisearch/components/Facet.vue';
import MediaTopics from '@/modules/aisearch/components/MediaTopics.vue';
import SaveQuery from '@/modules/aisearch/modals/SaveQuery.vue';
import api from '@/services/api';
import {
  ArticleListItem,
  ArticleMediaTopics,
  PromptArticle,
} from '@/services/api/modules/article.types';
import { Query } from '@/services/api/modules/queries';

import Results from './Results.vue';
import SourcesWeaviate from './SourcesWeaviate.vue';

export default defineComponent({
  props: {
    query: {
      type: Object as () => Query,
      required: false,
    },
    modalForm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    MediaTopics,
    SaveQuery,
    Facet,
    SourcesWeaviate,
    Results,
  },

  emits: ['done'],

  setup(props, { emit }) {
    const router = useRouter();
    const { t } = useI18n({});
    const route = useRoute();
    const store = useStore();

    const numberOptions = [15, 30, 50];

    const prioOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const periodOptions = [
      { text: t('search.period-options.no-period'), value: '0' },
      { text: t('search.period-options.24-hours'), value: '1' },
      { text: t('search.period-options.7-days'), value: '7' },
      { text: t('search.period-options.14-days'), value: '14' },
      { text: t('search.period-options.31-days'), value: '31' },
      { text: t('search.period-options.2-months'), value: '60' },
      { text: t('search.period-options.3-months'), value: '120' },
      { text: t('search.period-options.1-year'), value: '365' },
      { text: t('search.period-options.select-period'), value: '-1' },
    ];

    const languageOptions = [
      { text: t('search.language-options.nl'), value: 'nl' },
      { text: t('search.language-options.en'), value: 'en' },
      { text: t('search.language-options.de'), value: 'de' },
      { text: t('search.language-options.fr'), value: 'fr' },
      { text: t('search.language-options.es'), value: 'es' },
      { text: t('search.language-options.it'), value: 'it' },
      { text: t('search.language-options.sv'), value: 'sv' },
      { text: t('search.language-options.da'), value: 'da' },
      { text: t('search.language-options.fi'), value: 'fi' },
      { text: t('search.language-options.nb'), value: 'nb' },
    ];

    const categoryOptions = [
      { text: t('search.category-options.all'), value: '' },
      { text: t('search.category-options.bin'), value: 'BIN' },
      { text: t('search.category-options.bui'), value: 'BUI' },
      { text: t('search.category-options.eco'), value: 'ECO' },
      { text: t('search.category-options.spo'), value: 'SPO' },
    ];

    const newsEnabled = computed(() => store.getters['workspaces/newsEnabled']);
    const calendarEnabled = computed(
      () => store.getters['workspaces/calendarEnabled'],
    );
    //new change to the search options
    const sourceEnabled = computed(
      () => store.getters['workspaces/sourceEnabled'],
    );

    const { message, setMessage, clearMessage } = useMessage();

    const form = ref();
    const saveModal = ref();
    const accordion = ref();
    const accordionsources = ref();
    const accordionrefinements = ref();
    const loading = ref(false);
    const promptloading = ref(false);

    const loadingMore = ref(false);

    const items: Ref<ArticleListItem[]> = ref([]);
    const promptReferedArticles: Ref<PromptArticle[]> = ref([]);

    const count = ref(0);
    const offset = ref(0);
    const priority = ref(10);
    const showDescription = ref(false);
    const reduceLanguageBias = ref(false);
    const sourcesEl = ref();
    const sourceEl = ref();
    const calendarsEl = ref();
    const sources = ref();
    const category = ref();

    const source = ref();
    const calendars = ref();
    const type = ref(
      sourceEnabled.value
        ? SECTION_TYPES.NEWS
        : calendarEnabled.value
        ? SECTION_TYPES.CALENDAR
        : SECTION_TYPES.SOURCE,
    );
    const displayType = ref(DISPLAY_TYPES.EXTENDED);
    const sortBy = ref(SORT_TYPES.RELEVANCE);
    const vectorizer = ref('small3');
    const searchengine = ref('elastic');
    const queryText = ref(
      route.params.query ? (route.params.query as string) : '',
    );
    const promptAnswer = ref('');
    const notTerms = ref('');
    const queryConcept = ref('');
    const plusTerms = ref('');
    const queryTitle = ref('');
    const minWords = ref(25);
    const minScore = ref(useFeatures().features?.value?.embeddingsThreshold);
    const queryAuthor = ref('');
    const period = ref(periodOptions[0]);
    const language = ref([]);
    const facets = new Map();
    const mediatopics: Ref<ArticleMediaTopics[]> = ref([]);

    const range = ref();

    const numResults = ref(15);
    const sourceId = computed(() => sourceEl.value?.getSelectedIds());
    const sourcesIds = computed(() => sourcesEl.value?.getSelectedIds());
    const calendarsIds = computed(() => calendarsEl.value?.getSelectedIds());

    const formatDate = (date: Date) => format(date, 'dd-MM-yyyy');

    const fromDate = computed(() =>
      range.value?.start ? format(range.value?.start, 'yyyy-MM-dd') : undefined,
    );

    const toDate = computed(() =>
      range.value?.end ? format(range.value.end, 'yyyy-MM-dd') : undefined,
    );

    const languages = computed(() => language.value.map((a: any) => a.value));

    const resetState = () => {
      items.value = [];
      count.value = 0;
      mediatopics.value = [];
      accordion.value = undefined;
    };

    const setRangeTo7Days = () => {
      period.value =
        periodOptions.find((option) => option.value === '7') ||
        periodOptions[0];
    };

    const adjustMinimumScore = () => {
      if (minScore.value) {
        if (reduceLanguageBias.value) {
          minScore.value = minScore.value * 0.95;
          minScore.value = Math.round(minScore.value * 100) / 100;
        } else {
          minScore.value = minScore.value * (1 / 0.95);
          minScore.value = Math.round(minScore.value * 100) / 100;
        }
      }
    };

    const getArticleIds = computed(() => items.value.map(({ id }) => id));

    const handleSubmit = async () => {
      clearMessage();
      resetState();
      fetchResults();
      if (!props.query) {
        fetchMediaTopics();
      }
    };

    const loadMore = async () => {
      if (!loadingMore.value) {
        loadingMore.value = true;
        clearMessage();
        fetchResults();
      }
    };
    const handleKey = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.stopPropagation();
        event.preventDefault();
        handleSubmit();
      }
    };

    const fetchMediaTopics = async () => {
      const endpoint = api.search.conceptMediaTopics;
      const result = await endpoint(
        queryConcept.value,
        vectorizer.value,
        notTerms.value,
        plusTerms.value,
      )
        .catch(setMessage())
        .finally(() => {
          loading.value = false;
        });
      if (result) {
        mediatopics.value = result;
      }
    };

    const fetchQuestionAnswer = async () => {
      promptloading.value = true;
      const endpoint = api.search.answerPrompt;
      const result = await endpoint(queryConcept.value, getArticleIds.value)
        .catch(setMessage())
        .finally(() => {
          promptloading.value = false;
        });
      if (result) {
        promptAnswer.value = result.answer;
        promptReferedArticles.value = result.articles;
      }
    };

    const fetchResults = async () => {
      loading.value = true;
      promptAnswer.value = '';
      promptReferedArticles.value = [];
      const [lastItem] = items.value.slice(-1);

      const endpoint = api.search.weaviate;

      const defaultFrom =
        type.value === SECTION_TYPES.CALENDAR
          ? format(new Date(), 'yyyy-MM-dd')
          : undefined;

      let facetsRequested = ['source', 'locations', 'persons', 'organisations'];
      if (reduceLanguageBias.value) {
        facetsRequested = ['nolanguagebias'];
      } else {
        facetsRequested = [];
      }

      const result = await endpoint(
        {
          queryText: queryText.value,
          queryTitle: queryTitle.value,
          queryAuthor: queryAuthor.value,
          queryType: searchengine.value,
          facets: facetsRequested,
          languages: languages.value.filter((item) => item !== ''),
          sources: sourcesIds.value,
          period: period.value.value,
          fromDate: fromDate.value || defaultFrom,
          toDate: toDate.value,
          minWords: minWords.value,
          minScore: minScore.value,
          queryConcept: queryConcept.value,
          priority: priority.value,
          categories: category.value ? [category.value.value] : [],
          fromSubjects:
            notTerms.value && notTerms.value.length > 0
              ? notTerms.value.split(',').map(function (item) {
                  return item.trim();
                })
              : undefined,
          toSubjects:
            plusTerms.value && plusTerms.value.length > 0
              ? plusTerms.value.split(',').map(function (item) {
                  return item.trim();
                })
              : undefined,
        },
        {
          count: numResults.value,
          includeScore: 1,
          includeFacets: 0,
          highlight: displayType.value === DISPLAY_TYPES.SNIPPET,
          orderBy: sortBy.value === SORT_TYPES.RELEVANCE ? 'score' : 'time',
          offset: loadingMore.value ? offset.value : 0,
          vectorizer: vectorizer.value,
        },
      )
        .catch(setMessage())
        .finally(() => {
          loading.value = false;
        });

      if (result) {
        if (loadingMore.value) {
          if (result.title) {
            message.value = {
              text: result.title ? result.title : '',
              type: MESSAGE_TYPES.SUCCESS,
            };
          }
          items.value = [...items.value, ...result.items];
          loadingMore.value = false;
          offset.value = offset.value + result.items.length;
        } else {
          if (result.title) {
            message.value = {
              text: result.title ? result.title : '',
              type: MESSAGE_TYPES.SUCCESS,
            };
          }
          if (result.facets) {
            facets.clear();
            let facetList = [];
            result.facets.forEach(function (item) {
              let facetItems = facets.get(item.kind);
              if (facetItems == null) {
                facetItems = [];
                facets.set(item.kind, facetItems);
                facetList.push(item.kind);
              }
              facetItems.push(item);
            });
          }
          items.value = result.items;
          offset.value = result.items.length;
          count.value = result.resultCount || 0;
          showDescription.value = displayType.value !== DISPLAY_TYPES.COMPACT;
          if (queryConcept.value?.endsWith('?')) {
            if (count.value > 0) {
              fetchQuestionAnswer();
            } else {
              promptAnswer.value = t('common.no-articles-for-prompt');
            }
          }
        }
      }
    };

    const handleClick = async (itemId: string) => {
      await router.push({
        name: SEARCH_ARTICLE_AI,
        params: {
          id: route.params.id,
          resourceId: null,
          articleId: itemId,
        },
      });
    };

    const fillForm = (query: Query | undefined) => {
      if (query) {
        sources.value = query.sources;
        calendars.value = query.calendars;
        if (query.fromSubjects) {
          notTerms.value = query.fromSubjects;
        }
        if (query.toSubjects) {
          plusTerms.value = query.toSubjects;
        }
        source.value = query.source;
        minScore.value = query.minScore;
        if (query.vectorizer) {
          vectorizer.value = query.vectorizer;
        }
        if (query.minWords) {
          minWords.value = query.minWords;
        }
        sortBy.value = SORT_TYPES.DATE;
        reduceLanguageBias.value = query.reduceLanguageBias;
        queryConcept.value = query.queryConcept || '';
        queryText.value = query.queryText || '';
        queryTitle.value = query.queryTitle || '';
        queryAuthor.value = query.queryAuthor || '';
        if (
          query.sourceKinds != null &&
          query.sourceKinds.includes('CALENDAR')
        ) {
          type.value = SECTION_TYPES.CALENDAR;
        } else if (
          query.sourceKinds != null &&
          query.sourceKinds.includes('SOURCE')
        ) {
          type.value = SECTION_TYPES.SOURCE;
        } else if (
          query.sourceKinds != null &&
          query.sourceKinds.includes('NEWS')
        ) {
          type.value = SECTION_TYPES.NEWS;
        }

        period.value =
          periodOptions.find((option) => option.value === query.period) ||
          periodOptions[0];

        if (query.fromDate && query.fromDate) {
          range.value = {
            start: new Date(query.fromDate),
            end: new Date(query.fromDate),
          };
        }
      }
    };

    watch(type, resetState);
    watch(() => props.query, fillForm, { immediate: true });

    watch(sortBy, setRangeTo7Days);
    watch(reduceLanguageBias, adjustMinimumScore);

    const handleDone = () => emit('done');
    const showSave = () => saveModal.value?.show();
    return {
      SORT_TYPES,
      DISPLAY_TYPES,
      SECTION_TYPES,
      periodOptions,
      languageOptions,
      newsEnabled,
      calendarEnabled,
      sourceEnabled,
      message,
      form,
      loading,
      promptloading,
      saveModal,
      sources,
      source,
      sourceEl,
      sourcesEl,
      calendars,
      calendarsEl,
      accordion,
      accordionsources,
      accordionrefinements,
      items,
      count,
      language,
      priority,
      sourceId,
      showDescription,
      type,
      displayType,
      sortBy,
      minWords,
      minScore,
      queryText,
      queryConcept,
      notTerms,
      plusTerms,
      queryTitle,
      queryAuthor,
      period,
      range,
      vectorizer,
      searchengine,
      formatDate,
      fromDate,
      toDate,
      sourcesIds,
      calendarsIds,
      numberOptions,
      category,
      categoryOptions,
      prioOptions,
      numResults,
      reduceLanguageBias,
      handleSubmit,
      handleDone,
      loadMore,
      handleKey,
      loadingMore,
      facets,
      mediatopics,
      promptAnswer,
      promptReferedArticles,
      showSave,
      handleClick,
      ...useFeatures(),
      ...useValidation(),
    };
  },
});
