import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = { class: "row justify--end mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_anp_label = _resolveComponent("anp-label")!
  const _component_va_select = _resolveComponent("va-select")!
  const _component_va_alert = _resolveComponent("va-alert")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_anp_form = _resolveComponent("anp-form")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createBlock(_component_va_modal, {
    ref: "modal",
    stateful: true,
    title: _ctx.$t('news.move-news-feed'),
    "hide-default-actions": true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_anp_form, {
        ref: "form",
        onSubmit: _ctx.handleSubmit
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('news.move-news-feed-confirm')), 1),
          _createVNode(_component_anp_label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.directory')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_va_select, {
            modelValue: _ctx.workspace,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.workspace) = $event)),
            options: _ctx.workspaceOptions
          }, null, 8, ["modelValue", "options"]),
          (_ctx.isSharedWorkspace)
            ? (_openBlock(), _createBlock(_component_va_alert, {
                key: 0,
                color: "info",
                border: "left",
                class: "mt-6"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.save-shared-notice')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_va_button, {
              color: "primary",
              class: "mr-2",
              flat: "",
              onClick: _ctx.hide
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('actions.cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_va_button, {
              color: "primary",
              type: "submit",
              loading: _ctx.loading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('actions.move')), 1)
              ]),
              _: 1
            }, 8, ["loading"])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }, 8, ["title"]))
}