import { computed, ComputedRef } from 'vue';
import { useStore } from 'vuex';

import { UserFeatures } from '@/services/api/modules/auth';
import { State } from '@/store';

interface UseFeatures {
  features: ComputedRef<UserFeatures | undefined>;
}

export default (): UseFeatures => {
  const store = useStore<State>();
  const features = computed(() => store.state.auth.user?.features);

  return {
    features,
  };
};
