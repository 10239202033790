
import { defineComponent } from '@vue/runtime-core';

import { Message } from '@/hooks/useMessage';

export default defineComponent({
  props: {
    message: {
      type: Object as () => Message,
      required: false,
    },
  },
});
