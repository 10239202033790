import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_select = _resolveComponent("va-select")!

  return (_openBlock(), _createBlock(_component_va_select, {
    class: "select_greyed",
    modelValue: _ctx.selectedSearch,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedSearch) = $event)),
    options: _ctx.options,
    placeholder: _ctx.$t('common.section-select')
  }, null, 8, ["modelValue", "options", "placeholder"]))
}