import Blank from '@/components/layouts/Blank.vue';
import { PRIVACY_POLICY } from '@/config/routes';

import PrivacyPolicy from './views/PrivacyPolicy.vue';

export default [
  {
    path: '/pages',
    component: Blank,
    children: [
      {
        name: PRIVACY_POLICY,
        path: 'privacy-policy',
        component: PrivacyPolicy,
      },
    ],
  },
];
