import { NEWSLETTER_FORMATS } from '@/config/enums';

import http from '../http';

export interface Newsletter {
  id: string;
  name: string;
  mimeType: string;
  created: string;
}

export interface StoreNewsletter {
  name: string;
  format: NEWSLETTER_FORMATS;
  items: string[];
}

export interface DeleteNewsletter {
  name: string;
  format: NEWSLETTER_FORMATS;
  items: string[];
}

export interface MailNewsletter {
  id?: string;
  email: string;
  subject: string;
  message: string;
}

const index = (): Promise<Newsletter[]> => {
  return http.get('/newsletters');
};

const store = (item: StoreNewsletter): Promise<{ id: string }> => {
  return http.post('/newsletters', item);
};

const del = (id: string): Promise<undefined> => {
  return http.del(`/newsletters/${id}`);
};

const mail = (item: MailNewsletter): Promise<undefined> => {
  return http.post(`/newsletters/${item.id}/mail`, {
    username: item.email,
    subject: item.subject,
    comment: item.message,
  });
};

export default { index, store, del, mail };
