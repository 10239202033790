<template>
  <va-list-item>
    <va-list-item-section>
      <span class="all-loaded">
        {{ $t('news.all-items-loaded') }}
      </span>
    </va-list-item-section>
  </va-list-item>
</template>

<style lang="scss" scoped>
.all-loaded {
  margin: 0 auto;
  font-size: 0.875em;
  padding: 0.5rem 0;
}
</style>
