
import { defineComponent } from 'vue';

import ArticleModal from '@/components/modals/ArticleModal/Index.vue';
import { SEARCH } from '@/config/routes';

export default defineComponent({
  components: {
    ArticleModal,
  },

  setup() {
    return {
      SEARCH,
    };
  },
});
