import { ActionContext } from 'vuex';

import { COLLECTION_ACTIONS, COLLECTION_KINDS } from '@/config/enums';
import api from '@/services/api';
import {
  Collection,
  CollectionListItem,
  StoreClippings,
} from '@/services/api/modules/collections';
import { Drop } from '@/services/api/modules/sources';
import { State as R } from '@/store/index';

export interface State {
  items: Collection[];
  clippedItems: CollectionListItem[];
  clippings: string[];
  read: string[];
  searches: Drop[];
  publishQueryCollections: Collection[];
}

export default {
  namespaced: true,

  state: {
    items: [],
    clippedItems: [],
    clippings: [],
    read: [],
    searches: [],
    publishQueryCollections: [],
  },

  mutations: {
    setItems(state: State, items: Collection[]): void {
      state.items = items;
    },

    setClippedItems(state: State, items: CollectionListItem[]): void {
      state.clippedItems = items;
    },

    setPublishQueryCollections(state: State, items: Collection[]): void {
      state.publishQueryCollections = items;
    },

    addClippings(state: State, ids: string[]): void {
      state.clippings = [
        ...new Set([
          ...state.clippings,
          ...ids.map((id) => (id.includes('---') ? id.split('---')[1] : id)),
        ]),
      ];
    },

    removeClippings(state: State, ids: string[]): void {
      state.clippings = state.clippings.filter((id) => !ids.includes(id));
    },

    addRead(state: State, ids: string[]): void {
      state.read = [
        ...new Set([
          ...state.read,
          ...ids.map((id) => (id.includes('---') ? id.split('---')[1] : id)),
        ]),
      ];
    },
    setSearches(state: State, searches: Drop[]): void {
      state.searches = searches;
    },
  },

  actions: {
    async fetchItems({
      commit,
      dispatch,
    }: ActionContext<State, R>): Promise<void> {
      const items = await api.collections.index();
      commit('setItems', items);
      dispatch('fetchClippedItems');
    },

    async fetchClippedItems({
      commit,
      state,
    }: ActionContext<State, R>): Promise<void> {
      const workFolder = state.items.find(
        (item) => item.kind === COLLECTION_KINDS.WORKFOLDER,
      );

      if (workFolder) {
        const collection = await api.collections.items(workFolder.id, {
          count: 200,
        });

        commit('addClippings', collection.clipped);
        commit('setClippedItems', collection.items);
      }
    },

    async addClippings(
      { commit, state }: ActionContext<State, R>,
      item: StoreClippings,
    ): Promise<void> {
      const workFolder = state.items.find(
        (item) => item.kind === COLLECTION_KINDS.WORKFOLDER,
      );

      if (workFolder) {
        await api.collections.storeClippings({
          id: workFolder.id,
          action: COLLECTION_ACTIONS.ADD,
          ...item,
        });
      }

      commit('addClippings', item.items);
    },

    async removeClippings(
      { commit, state }: ActionContext<State, R>,
      item: StoreClippings,
    ): Promise<void> {
      item.items = item.items.map((id) =>
        id.includes('---') ? id.split('---')[1] : id,
      );

      const first = state.items.find(
        (item) => item.kind === COLLECTION_KINDS.WORKFOLDER,
      );

      if (first) {
        await api.collections.storeClippings({
          id: first.id,
          action: COLLECTION_ACTIONS.REMOVE,
          ...item,
          items: item.items.map((id) => `${first.id}---${id}`),
        });
      }

      commit('removeClippings', item.items);
    },
    async fetchPublishQueryCollections({
      commit,
    }: ActionContext<State, R>): Promise<void> {
      const items = await api.collections.publishQueryCollections();
      commit('setPublishQueryCollections', items);
    },
  },
  getters: {
    getSearches: (state: State): Drop[] => {
      return state.searches;
    },
    publishQueryCollections: (state: State): Collection[] =>
      state.publishQueryCollections,
  },
};
