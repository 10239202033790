import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_button = _resolveComponent("va-button")!
  const _component_anp_popover = _resolveComponent("anp-popover")!
  const _component_anp_link = _resolveComponent("anp-link")!
  const _component_va_spacer = _resolveComponent("va-spacer")!
  const _component_va_list_item_label = _resolveComponent("va-list-item-label")!
  const _component_va_list_item_section = _resolveComponent("va-list-item-section")!
  const _component_va_list_item = _resolveComponent("va-list-item")!
  const _component_va_list = _resolveComponent("va-list")!
  const _component_va_button_dropdown = _resolveComponent("va-button-dropdown")!
  const _component_va_app_bar = _resolveComponent("va-app-bar")!

  return (_openBlock(), _createBlock(_component_va_app_bar, {
    class: "navigation-top",
    color: "white"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_anp_popover, {
        message: _ctx.open ? _ctx.$t('common.close-sidebar') : _ctx.$t('common.open-sidebar')
      }, {
        default: _withCtx(() => [
          (_ctx.showToggle)
            ? (_openBlock(), _createBlock(_component_va_button, {
                key: 0,
                icon: _ctx.icon,
                color: "primary",
                class: "mr-4",
                flat: "",
                onClick: _ctx.toggleOpen
              }, null, 8, ["icon", "onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["message"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topItems, (item) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (item.render)
            ? (_openBlock(), _createElementBlock("div", {
                key: item.id
              }, [
                (item.to)
                  ? (_openBlock(), _createBlock(_component_va_button, {
                      key: 0,
                      color: "primary",
                      flat: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_anp_link, {
                          to: item.to
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.title), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 256)),
      _createVNode(_component_va_spacer),
      _createVNode(_component_anp_popover, {
        message: _ctx.$t('common.account')
      }, {
        default: _withCtx(() => [
          (_ctx.authenticated)
            ? (_openBlock(), _createBlock(_component_va_button_dropdown, {
                key: 0,
                icon: "person",
                color: "primary",
                position: "bottom-end",
                flat: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_va_list, { class: "px-0 py-1 -m-2" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accountNavigation, (item) => {
                        return (_openBlock(), _createBlock(_component_va_list_item, {
                          key: item.id,
                          to: item.to
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_va_list_item_section, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_va_list_item_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.title), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["to"]))
                      }), 128)),
                      _createVNode(_component_va_list_item, { onClick: _ctx.handleLogout }, {
                        default: _withCtx(() => [
                          _createVNode(_component_va_list_item_section, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_va_list_item_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('auth.logout')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["message"])
    ]),
    _: 1
  }))
}