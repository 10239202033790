
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import useMessage from '@/hooks/useMessage';
import useValidation from '@/hooks/useValidation';

export default defineComponent({
  setup() {
    const { message, setMessage, clearMessage } = useMessage();
    const store = useStore();
    const password = ref('');
    const repeatPassword = ref('');

    const save = () => {
      clearMessage();

      if (password.value || repeatPassword.value) {
        return store
          .dispatch('auth/changePassword', {
            password: password.value,
          })
          .then(setMessage('auth.new-password-success'))
          .catch(setMessage());
      }
    };

    return {
      message,
      password,
      repeatPassword,
      save,
      ...useValidation(),
    };
  },
});
