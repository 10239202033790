import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f01ea39"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "entities" }
const _hoisted_2 = { class: "display-6" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_chip = _resolveComponent("va-chip")!
  const _component_anp_popover = _resolveComponent("anp-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.$t('common.mediaTopics')), 1),
      (_ctx.showScore)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, " (" + _toDisplayString(_ctx.vectorizer) + ")", 1))
        : _createCommentVNode("", true)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredSources, (entity, index) => {
      return (_openBlock(), _createBlock(_component_anp_popover, {
        key: index,
        message: entity.label + '(' + entity.confidence + ')'
      }, {
        default: _withCtx(() => [
          _createVNode(_component_va_chip, {
            outline: "",
            size: "small",
            onClick: ($event: any) => (_ctx.handleClick(entity.id))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(entity.label), 1),
              (_ctx.showScore)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, " (" + _toDisplayString(entity.confidence) + ")", 1))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]),
        _: 2
      }, 1032, ["message"]))
    }), 128))
  ]))
}