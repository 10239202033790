
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import { SECTION_TYPES } from '@/config/enums';

export default defineComponent({
  props: {
    type: {
      type: String as () => SECTION_TYPES,
      required: true,
      validator: (value: SECTION_TYPES) =>
        Object.values(SECTION_TYPES).includes(value),
    },
  },

  setup(props) {
    const { t } = useI18n();

    const message = computed(() =>
      props.type === SECTION_TYPES.CALENDAR
        ? t('calendar.items-not-found')
        : props.type === SECTION_TYPES.NEWS
        ? t('news.items-not-found')
        : t('source.items-not-found'),
    );

    return { message };
  },
});
