import Base from '@/components/layouts/Base.vue';
import { AI_SEARCH, SEARCH_ARTICLE_AI } from '@/config/routes';

import Article from './views/Article.vue';
import Index from './views/Index.vue';

export default [
  {
    path: '/aisearch',
    component: Base,
    children: [
      {
        name: AI_SEARCH,
        path: '',
        component: Index,
        children: [
          {
            name: SEARCH_ARTICLE_AI,
            path: 'artikel/:articleId',
            component: Article,
          },
        ],
      },
    ],
  },
];
