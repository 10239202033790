
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from '@vue/runtime-core';
import { groupBy } from 'lodash';
import { useStore } from 'vuex';

import useFeatures from '@/hooks/useFeatures';
import { State } from '@/store';

import CreateNewsletter from '../modals/CreateNewsletter.vue';
import DeleteClippings from '../modals/DeleteClippings.vue';

export default defineComponent({
  components: {
    CreateNewsletter,
    DeleteClippings,
  },

  setup() {
    const store = useStore<State>();
    const createModal = ref();
    const deleteModal = ref();
    const showError = ref();
    const selection = ref();

    const clippedItems = computed(() => store.state.collections.clippedItems);
    const selectedItems = computed<string[]>(() =>
      selection.value?.getSelectedIds(),
    );

    const init = computed(() => clippedItems.value.map((item) => item.id));
    const groups = computed(() => groupBy(clippedItems.value, 'originTitle'));

    const showCreate = () => {
      if (selectedItems.value?.length) {
        createModal.value?.show();
      } else {
        showError.value = true;
      }
    };

    const showDelete = () => {
      if (selectedItems.value?.length) {
        deleteModal.value?.show();
      }
    };

    onMounted(() => {
      store.dispatch('collections/fetchClippedItems');
    });

    watch([clippedItems, selectedItems], () => {
      if (showError.value) showError.value = false;
    });

    return {
      createModal,
      deleteModal,
      showCreate,
      showDelete,
      showError,
      selection,
      clippedItems,
      selectedItems,
      groups,
      init,
      ...useFeatures(),
    };
  },
});
