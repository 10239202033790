
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import { SECTION_TYPES } from '@/config/enums';
import useValidation from '@/hooks/useValidation';

export default defineComponent({
  props: {
    clippingsIds: {
      type: Array as () => string[],
      required: false,
    },
  },

  setup(props) {
    const store = useStore();
    const modal = ref();
    const form = ref();
    const loading = ref(false);

    const hide = () => modal.value?.hide();
    const show = () => modal.value?.show();

    const handleCancel = () => {
      hide();
    };

    const handleDelete = async () => {
      loading.value = true;

      await store.dispatch('collections/removeClippings', {
        items: props.clippingsIds,
      });

      store
        .dispatch('collections/fetchClippedItems')
        .then(() => {
          modal.value?.hide();
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      SECTION_TYPES,
      modal,
      form,
      loading,
      hide,
      show,
      confirm,
      handleCancel,
      handleDelete,
      ...useValidation(),
    };
  },
});
