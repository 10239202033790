
import { defineComponent } from 'vue';

const helpTypes = ['and', 'or', 'not', 'hooks', 'wildcard', 'quotes'];

export default defineComponent({
  setup() {
    return { helpTypes };
  },
});
