
import { cloneDeep } from 'lodash';
import { computed, defineComponent } from 'vue';
import { useQuery } from 'vue-query';
import { useRouter } from 'vue-router';

import config from '@/config';
import { CONTEXT_TYPES } from '@/config/enums';
import { NEWS_ARTICLE } from '@/config/routes';
import api from '@/services/api';
import { Resource, Workspace } from '@/services/api/modules/workspaces';

export default defineComponent({
  props: {
    resource: {
      type: Object as () => Resource,
      required: true,
    },
    workspace: {
      type: Object as () => Workspace,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();
    const type = computed(() =>
      props.resource.hasEventContext
        ? CONTEXT_TYPES.EVENTS
        : CONTEXT_TYPES.PLANNED,
    );

    const fetchLiveAgenda = () => {
      return api.sources.context(props.resource.resourceId, type.value);
    };

    const { data } = useQuery(
      ['live-agenda', { id: props.resource.resourceId }],
      fetchLiveAgenda,
      {
        refetchInterval: config.interval.liveAgenda,
      },
    );

    const handleClick = async () => {
      await router.push({
        name: NEWS_ARTICLE,
        params: {
          id: props.workspace.id,
          resourceId: props.resource.resourceId,
          articleId: data.value?.items[0].id,
        },
      });

      history.replaceState(
        {
          ...history.state,
          articles: cloneDeep(data.value?.items),
          articleIds: data.value?.items.map((item) => item.id),
        },
        '',
      );
    };

    return {
      data,
      handleClick,
    };
  },
});
