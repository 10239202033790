import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = { class: "row justify--end mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_anp_label = _resolveComponent("anp-label")!
  const _component_va_select = _resolveComponent("va-select")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_anp_form = _resolveComponent("anp-form")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createBlock(_component_va_modal, {
    ref: "modal",
    stateful: true,
    title: _ctx.$t('news.duplicate-news-feed'),
    "hide-default-actions": true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_anp_form, {
        ref: "form",
        onSubmit: _ctx.handleSubmit
      }, {
        default: _withCtx(() => [
          (_ctx.hasAccess)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('news.duplicate-news-feed-confirm')), 1)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('news.duplicate-news-feed-no-access')), 1),
                _createVNode(_component_anp_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('common.directory')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_va_select, {
                  modelValue: _ctx.workspace,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.workspace) = $event)),
                  options: _ctx.workspaceOptions
                }, null, 8, ["modelValue", "options"])
              ], 64)),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_va_button, {
              color: "primary",
              class: "mr-2",
              flat: "",
              onClick: _ctx.hide
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('actions.cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_va_button, {
              color: "primary",
              type: "submit",
              loading: _ctx.loading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('actions.duplicate')), 1)
              ]),
              _: 1
            }, 8, ["loading"])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }, 8, ["title"]))
}