import { ActionContext } from 'vuex';

import api from '@/services/api';
import {
  MailNewsletter,
  Newsletter,
  StoreNewsletter,
} from '@/services/api/modules/newsletters';
import { State as R } from '@/store/index';

export interface State {
  items: Newsletter[];
}

export default {
  namespaced: true,

  state: {
    items: [],
  },

  mutations: {
    setItems(state: State, items: Newsletter[]): void {
      state.items = items;
    },
    removeItems(state: State, ids: string[]): void {
      state.items = state.items.filter((item) => !ids.includes(item.id));
    },
  },

  actions: {
    async fetchItems({ commit }: ActionContext<State, R>): Promise<void> {
      const items = await api.newsletters.index();
      commit('setItems', items);
    },

    async saveItem(
      { commit }: ActionContext<State, R>,
      item: StoreNewsletter,
    ): Promise<void> {
      await api.newsletters.store(item);
      const items = await api.newsletters.index();
      commit('setItems', items);
    },

    async deleteItems(
      { commit }: ActionContext<State, R>,
      ids: string[],
    ): Promise<void> {
      await Promise.all(ids.map(async (id) => api.newsletters.del(id)));
      commit('removeItems', ids);
    },

    async sendMail(
      _: ActionContext<State, R>,
      item: MailNewsletter,
    ): Promise<void> {
      await api.newsletters.mail(item);
    },
  },
};
