import { ActionContext } from 'vuex';

import http from '@/services/api/http';
import {
  ArticleMail,
  FeedbackOnQueryResult,
} from '@/services/api/modules/article.types';
import { State as R } from '@/store/index';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State {}

export default {
  namespaced: true,

  state: {
    items: [],
  },

  actions: {
    async feedback(
      { commit }: ActionContext<State, R>,
      item: FeedbackOnQueryResult,
    ): Promise<void> {
      return http.post(
        `/queries/${item.queryId}/items/${item.articleId}/feedback`,
        {
          score: item.score,
          ok: item.ok,
        },
      );
    },
  },

  getters: {},
};
