
import { defineComponent } from 'vue';

import policy from '@/locales/privacy-policy-nl.json';

export default defineComponent({
  setup() {
    return { policy };
  },
});
