import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-273fd315"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "calendar p-4" }
const _hoisted_2 = { class: "toolbar" }
const _hoisted_3 = { class: "view row gutter--xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_anp_popover = _resolveComponent("anp-popover")!
  const _component_va_checkbox = _resolveComponent("va-checkbox")!
  const _component_va_select = _resolveComponent("va-select")!
  const _component_WeekView = _resolveComponent("WeekView")!
  const _component_DayView = _resolveComponent("DayView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createVNode(_component_anp_popover, {
            message: _ctx.$t('common.print')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_va_button, {
                onClick: _withModifiers(_ctx.handlePrint, ["prevent"]),
                icon: "print",
                flat: ""
              }, null, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["message"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_va_checkbox, {
            modelValue: _ctx.changed,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.changed) = $event)),
            label: _ctx.$t('common.changed')
          }, null, 8, ["modelValue", "label"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_anp_popover, {
            message: _ctx.$t('common.previous')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_va_button, {
                icon: "chevron_left",
                flat: "",
                round: "",
                onClick: _ctx.previous
              }, null, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["message"]),
          _createVNode(_component_va_button, {
            flat: "",
            onClick: _ctx.today
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.today')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_anp_popover, {
            message: _ctx.$t('common.next')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_va_button, {
                icon: "chevron_right",
                flat: "",
                round: "",
                onClick: _ctx.next
              }, null, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["message"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_va_select, {
            modelValue: _ctx.type,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.type) = $event)),
            options: _ctx.typeOptions,
            width: "140px"
          }, null, 8, ["modelValue", "options"])
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.type.value === 'week')
          ? (_openBlock(), _createBlock(_component_WeekView, {
              key: 0,
              resourceId: _ctx.resourceId,
              isLoading: _ctx.isLoading,
              items: _ctx.items,
              position: _ctx.position,
              workspace: _ctx.workspace,
              resource: _ctx.resource
            }, null, 8, ["resourceId", "isLoading", "items", "position", "workspace", "resource"]))
          : _createCommentVNode("", true),
        (_ctx.type.value === 'day')
          ? (_openBlock(), _createBlock(_component_DayView, {
              key: 1,
              resourceId: _ctx.resourceId,
              isLoading: _ctx.isLoading,
              items: _ctx.items,
              position: _ctx.position,
              workspace: _ctx.workspace,
              resource: _ctx.resource
            }, null, 8, ["resourceId", "isLoading", "items", "position", "workspace", "resource"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}