
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

import { SEARCH } from '@/config/routes';
import { Article } from '@/services/api/modules/article.types';

export default defineComponent({
  props: {
    article: {
      type: Object as () => Article,
      required: true,
    },
  },

  setup(props) {
    const showMoreSources = ref(false);
    const router = useRouter();

    const filteredSources = computed(() =>
      showMoreSources.value
        ? props.article.quotedSources
        : props.article.quotedSources.slice(0, 5),
    );

    const handleClick = (tag: string) => {
      router.push({ name: SEARCH, params: { query: tag } });
    };

    return {
      showMoreSources,
      filteredSources,
      handleClick,
    };
  },
});
