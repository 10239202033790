
import { defineComponent } from '@vue/runtime-core';

import Form from '../components/Form.vue';
import Help from '../components/Help.vue';

export default defineComponent({
  components: {
    Form,
    Help,
  },
});
