import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'vuestic-ui/dist/vuestic-ui.css';
import '@/styles/index.scss';

import { GlobalConfig, VuesticPlugin } from 'vuestic-ui';

export const config: GlobalConfig = {
  colors: {
    primary: '#0039D8',
    'primary-hover': '#214AF5',
    'primary-light': '#CED8F6',
    'primary-light-hover': '#b7c5f1',
    secondary: '#ece94a',
    background: '#f6f7fc',
    'light-gray': '#e8ecfb',
    'text-color': 'var(--va-darkest-gray)',
    danger: '#D32F2F',
    'danger-light': '#ff9191',
    'danger-light-hover': '#ff7878',
    white: '#FFFFFF',
  },
  components: {
    VaSelect: {
      maxHeight: '400px',
    },
  },
};

export default VuesticPlugin;
