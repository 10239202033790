
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import { AUTH_FORGOT_PASSWORD } from '@/config/routes';
import useMessage from '@/hooks/useMessage';
import useValidation from '@/hooks/useValidation';

export default defineComponent({
  setup() {
    const store = useStore();
    const { message, setMessage, clearMessage } = useMessage();
    const form = ref();
    const loading = ref(false);
    const email = ref('');
    const password = ref('');
    const remember = ref(true);

    const handleSubmit = () => {
      clearMessage();
      loading.value = true;

      store
        .dispatch('auth/login', {
          email: email.value,
          password: password.value,
          remember: remember.value,
        })
        .catch((error) => {
          const data = error.response.json();

          data.then((data: any) => {
            if (data.data.location != null && error.response.status == 403) {
              location.href = data.data.location;
            }
          });
          loading.value = false;
          setMessage()(error);
        });
    };

    const handleGoogle = () => {
      store.dispatch('auth/google');
    };

    const handleMicrosoft = () => {
      store.dispatch('auth/microsoft');
    };

    return {
      AUTH_FORGOT_PASSWORD,

      message,
      form,
      loading,
      email,
      password,
      remember,
      handleSubmit,
      handleGoogle,
      handleMicrosoft,
      ...useValidation(),
    };
  },
});
