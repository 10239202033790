
import { defineComponent, ref } from 'vue';

import { SECTION_TYPES } from '@/config/enums';
import useValidation from '@/hooks/useValidation';

export default defineComponent({
  props: {
    type: {
      type: String as () => SECTION_TYPES,
      required: true,
    },
  },

  setup() {
    const modal = ref();
    const form = ref();

    const hide = () => modal.value?.hide();
    const show = () => modal.value?.show();

    return {
      SECTION_TYPES,
      modal,
      form,
      hide,
      show,
      ...useValidation(),
    };
  },
});
