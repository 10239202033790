import { pick } from 'lodash';

import mapEvents from '@/services/mapping/mapEvents';

import http from '../http';
import listOptions, { ListOptions } from '../listOptions';
import {
  Article,
  ArticleList,
  ArticleMail,
  ArticleMedia,
  ArticleMediaTopics,
  ArticleRelated,
  PromptResult,
} from './article.types';

export interface SearchQuery {
  id?: string;
  queryText?: string;
  queryTitle?: string;
  queryAuthor?: string;
  period?: string;
  fromDate?: string;
  toDate?: string;
  sources?: string[];
  languages?: string[];
  calendars?: string[];
  queryType?: string;
  categories?: string[];
  fromSubjects?: string[];
  toSubjects?: string[];
  facets?: string[];
  minWords?: number;
  minScore?: number;
  priority?: number;
  onlyPrio?: string;
  queryConcept?: string;
}

const news = (
  item: SearchQuery,
  options: ListOptions,
): Promise<ArticleList> => {
  const params = listOptions(options);

  return http.post(
    `/items/searchresult${params}`,
    pick(item, [
      'queryText',
      'queryTitle',
      'queryAuthor',
      'period',
      'fromDate',
      'toDate',
      'sources',
    ]),
  );
};

const weaviate = (
  item: SearchQuery,
  options: ListOptions,
): Promise<ArticleList> => {
  const params = listOptions(options);
  return http.post(
    `/items/searchresult${params}`,
    pick(item, [
      'queryText',
      'queryTitle',
      'queryAuthor',
      'period',
      'fromDate',
      'toDate',
      'sources',
      'languages',
      'queryType',
      'categories',
      'fromSubjects',
      'toSubjects',
      'calendars',
      'facets',
      'minWords',
      'minScore',
      'priority',
      'onlyPrio',
      'queryConcept',
    ]),
  );
};

const calendar = async (
  item: SearchQuery,
  options: ListOptions,
): Promise<ArticleList> => {
  const params = listOptions(options);

  const data = await http.post(
    `/calendars/items/searchresult${params}`,
    pick(item, ['queryText', 'period', 'fromDate', 'toDate', 'calendars']),
  );

  return {
    ...data,
    items: mapEvents(data.items),
  };
};

const article = (
  _: string,
  id: string,
  options?: ListOptions,
): Promise<Article> => {
  const params = listOptions(options);
  return http.get(`/items/searchresult/${id}${params}`);
};

const articleMedia = (_: string, id: string): Promise<ArticleMedia[]> => {
  return http.get(`/items/searchresult/${id}/media`);
};

const articleMediaTopics = (
  id: string,
  options?: ListOptions,
): Promise<ArticleMediaTopics[]> => {
  const params = listOptions(options);
  return http.get(`/items/${id}/mediatopics${params}`);
};

const conceptMediaTopics = (
  concept: string,
  model: string,
  moveFrom: string,
  moveTo: string,
): Promise<ArticleMediaTopics[]> => {
  return http.post(`/mediatopics/related`, {
    concept: concept,
    model: model,
    moveTo: moveTo,
    moveFrom: moveFrom,
  });
};

const conceptTags = (
  concept: string,
  model: string,
  moveFrom: string,
  moveTo: string,
  minScore: number,
  modelIds: number[],
): Promise<ArticleMediaTopics[]> => {
  return http.post(`/mediatopics/relatedtags`, {
    concept: concept,
    model: model,
    moveTo: moveTo,
    moveFrom: moveFrom,
    minScore: minScore,
    modelIds: modelIds,
  });
};

const articleRelated = (
  _: string,
  id: string,
  options: ListOptions,
): Promise<ArticleRelated> => {
  const params = listOptions(options);
  return http.get(`/items/searchresult/${id}/related${params}`);
};

const articleMail = (item: ArticleMail): Promise<undefined> => {
  return http.post(`/items/searchresult/${item.id}/mail`, {
    username: item.email,
    subject: item.subject,
    comment: item.message,
  });
};

const answerPrompt = (
  question: string,
  articleIds: string[],
): Promise<PromptResult> => {
  return http.post(`/items/prompt`, {
    question: question,
    articleid: articleIds,
  });
};

export default {
  news,
  calendar,
  weaviate,
  article,
  articleMedia,
  articleRelated,
  articleMail,
  articleMediaTopics,
  conceptMediaTopics,
  conceptTags,
  answerPrompt,
};
