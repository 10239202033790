import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_form = _resolveComponent("va-form")!

  return (_openBlock(), _createBlock(_component_va_form, {
    ref: "form",
    tag: "form",
    onSubmit: _withModifiers(_ctx.handleSubmit, ["prevent"])
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["onSubmit"]))
}