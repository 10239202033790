
import { computed, defineComponent } from 'vue';
import { useQueryProvider } from 'vue-query';
import { useStore } from 'vuex';

import { State } from '@/store';

import useInit from './hooks/useInit';

export default defineComponent({
  setup() {
    const store = useStore<State>();

    const fontSize = computed<string>(() => store.getters['objects/fontSize']);
    const fontSizeClass = computed(() => `font-size-${fontSize.value}`);

    useInit();
    useQueryProvider();

    return {
      fontSizeClass,
    };
  },
});
