
import { computed, defineComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';

import { DISPLAY_TYPES, RESOURCE_KINDS, WORKSPACE_TYPES } from '@/config/enums';
import useValidation from '@/hooks/useValidation';
import { Resource, Workspace } from '@/services/api/modules/workspaces';

const getDisplay = (resource: Resource) => {
  const { settings } = resource;

  if (settings) {
    if (settings.showCompact) return DISPLAY_TYPES.COMPACT;
    if (settings.showSnippet) return DISPLAY_TYPES.SNIPPET;
    if (settings.showDetails) return DISPLAY_TYPES.EXTENDED;
  }

  return DISPLAY_TYPES.DEFAULT;
};

export default defineComponent({
  props: {
    resource: {
      type: Object as () => Resource,
      required: true,
    },
    workspace: {
      type: Object as () => Workspace,
      required: true,
    },
  },

  setup(props) {
    const store = useStore();
    const modal = ref();
    const loading = ref(false);

    const name = ref(props.resource.title);
    const display = ref(getDisplay(props.resource));
    const images = ref(props.resource.settings?.showImages || false);

    const isEditable = computed(() => props.workspace.editable);
    const isPrivate = computed(
      () => props.workspace.wsType === WORKSPACE_TYPES.PRIVATE,
    );
    const isQuery = computed(
      () => props.resource.resourceKind === RESOURCE_KINDS.QUERY,
    );

    const hide = () => modal.value?.hide();
    const show = () => modal.value?.show();

    const handleSubmit = () => {
      loading.value = true;

      if (props.resource.title !== name.value) {
        store.dispatch('workspaces/renameResourceItem', {
          resourceId: props.resource.resourceId,
          name: name.value,
        });
      }

      store
        .dispatch('workspaces/updateResourceItem', {
          resourceId: props.resource.resourceId,
          settings: {
            showDefault: display.value === DISPLAY_TYPES.DEFAULT,
            showCompact: display.value === DISPLAY_TYPES.COMPACT,
            showSnippet: display.value === DISPLAY_TYPES.SNIPPET,
            showDetails: display.value === DISPLAY_TYPES.EXTENDED,
            showImages: images.value,
          },
        })
        .then(() => {
          modal.value?.hide();
        })
        .finally(() => {
          loading.value = false;
        });
    };

    watch(display, () => {
      if (display.value === DISPLAY_TYPES.COMPACT) {
        images.value = false;
      }
    });

    return {
      DISPLAY_TYPES,
      modal,
      loading,
      name,
      display,
      images,
      isEditable,
      isPrivate,
      isQuery,
      hide,
      show,
      handleSubmit,
      ...useValidation(),
    };
  },
});
