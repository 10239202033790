
import { computed, defineComponent } from 'vue';
import { useQuery } from 'vue-query';
import { useStore } from 'vuex';

import { ARTICLE_MEDIA_TYPES, RESOURCE_KINDS } from '@/config/enums';
import api from '@/services/api';
import { Resource } from '@/services/api/modules/workspaces';
import { State } from '@/store';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
    },
    articleId: {
      type: String,
      required: true,
    },
    resource: {
      type: Object as () => Resource,
      required: false,
    },
  },

  setup(props) {
    const store = useStore<State>();

    const baseUri = computed(() => store.state.global.api.uri);

    const apiModule = computed(() => {
      if (!props.resource) return api.search;

      switch (props.resource?.resourceKind) {
        case RESOURCE_KINDS.CALENDARS:
          return api.calendars;
        case RESOURCE_KINDS.NEWSLETTERCONTENT:
          return api.newslettercontent;
        case RESOURCE_KINDS.QUERY:
          return api.queries;
        case RESOURCE_KINDS.SOURCE:
        default:
          return api.sources;
      }
    });

    const fetchMedia = () => {
      return apiModule.value.articleMedia(
        props.resource?.resourceId as string,
        props.articleId,
      );
    };

    const query = useQuery(
      ['article-media', { articleId: props.articleId }],
      fetchMedia,
    );

    const image = computed(() =>
      query.data.value?.find(
        (item) => item.kind === ARTICLE_MEDIA_TYPES.IMAGE_SMALL,
      ),
    );

    const audio = computed(() =>
      query.data.value?.find((item) => item.kind === ARTICLE_MEDIA_TYPES.AUDIO),
    );

    const video = computed(() =>
      query.data.value?.find((item) => item.kind === ARTICLE_MEDIA_TYPES.VIDEO),
    );

    const localfocuswidget = computed(() =>
      query.data.value?.find(
        (item) => item.kind === ARTICLE_MEDIA_TYPES.LOCALFOCUSWIDGET,
      ),
    );

    const getMediaUrl = (id: string) =>
      props.resource?.resourceKind === RESOURCE_KINDS.NEWSLETTERCONTENT
        ? `${baseUri.value}newslettercontent/${props.resource?.resourceId}/items/${props.articleId}/media/${id}`
        : `${baseUri.value}sources/${props.resource?.resourceId}/items/${props.articleId}/media/${id}`;

    return {
      ARTICLE_MEDIA_TYPES,
      baseUri,
      image,
      audio,
      video,
      localfocuswidget,
      getMediaUrl,
    };
  },
});
