
import { defineComponent } from '@vue/runtime-core';

import useFeatures from '@/hooks/useFeatures';

import Clippings from '../components/Clippings.vue';
import Newsletters from '../components/Newsletters.vue';

export default defineComponent({
  components: {
    Clippings,
    Newsletters,
  },

  setup() {
    return {
      ...useFeatures(),
    };
  },
});
