
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import { WORKSPACE_KINDS, WORKSPACE_TYPES } from '@/config/enums';
import useValidation from '@/hooks/useValidation';
import { Resource, Workspace } from '@/services/api/modules/workspaces';
import { State } from '@/store';

export default defineComponent({
  props: {
    resource: {
      type: Object as () => Resource,
      required: true,
    },
    workspaceId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const store = useStore<State>();

    const accessTypes = computed(() => {
      const features = store.state.auth.user?.features;
      const types = [WORKSPACE_TYPES.PRIVATE];

      if (features?.manageSharedWorkspaces) {
        types.push(WORKSPACE_TYPES.SHARED);
      }

      return types;
    });

    const workspaces = computed(
      () => store.getters['workspaces/getItems'] as Workspace[],
    );

    const workspaceOptions = computed(() => {
      return workspaces.value
        ?.filter(
          (item) =>
            item.wsKind === WORKSPACE_KINDS.COLUMN &&
            accessTypes.value?.includes(item.wsType) &&
            item.id !== props.workspaceId,
        )
        .map((item) => ({ text: item.title, value: item.id }));
    });

    const workspace = ref(workspaceOptions.value[0]);
    const modal = ref();
    const loading = ref(false);

    const isSharedWorkspace = computed(
      () =>
        workspaces.value.find((item) => item.id === workspace.value.value)
          ?.wsType === WORKSPACE_TYPES.SHARED,
    );

    const hide = () => modal.value?.hide();
    const show = () => modal.value?.show();

    const handleSubmit = () => {
      loading.value = true;

      store
        .dispatch('workspaces/updateResourceItem', {
          workspaceId: workspace.value.value,
          resourceId: props.resource.resourceId,
        })
        .then(() => {
          modal.value?.hide();
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      accessTypes,
      workspaceOptions,
      workspace,
      isSharedWorkspace,
      modal,
      loading,
      hide,
      show,
      handleSubmit,
      ...useValidation(),
    };
  },
});
