import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-530cd46c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "nested"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_sidebar_item_title = _resolveComponent("va-sidebar-item-title")!
  const _component_Controls = _resolveComponent("Controls")!
  const _component_va_sidebar_item_content = _resolveComponent("va-sidebar-item-content")!
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_va_sidebar_item = _resolveComponent("va-sidebar-item")!
  const _component_va_sidebar = _resolveComponent("va-sidebar")!

  return (_openBlock(), _createBlock(_component_va_sidebar, {
    class: "navigation-sub",
    width: _ctx.wide ? '42rem' : '16rem',
    color: "primary-light"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_va_sidebar_item_content, { class: "title" }, {
        default: _withCtx(() => [
          _createVNode(_component_va_sidebar_item_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.directories')), 1)
            ]),
            _: 1
          }),
          (
          (_ctx.features?.managePrivateWorkspaces ||
            _ctx.features?.manageSharedWorkspaces) &&
          _ctx.controls
        )
            ? (_openBlock(), _createBlock(_component_Controls, {
                key: 0,
                controls: _ctx.controls
              }, null, 8, ["controls"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: item.id
        }, [
          (item.render)
            ? (_openBlock(), _createBlock(_component_va_sidebar_item, {
                key: 0,
                onClick: ($event: any) => (_ctx.toggleOpen(item.id)),
                active: _ctx.isActive(item.id, index),
                "text-color": "primary",
                "active-color": "#0039d644",
                "hover-color": "#0039d622"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_va_sidebar_item_content, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_va_sidebar_item_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.title), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_va_icon, {
                        name: _ctx.getIcon(item.id)
                      }, null, 8, ["name"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["onClick", "active"]))
            : _createCommentVNode("", true),
          (_ctx.openState[item.id] && item.children)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    (child.render)
                      ? (_openBlock(), _createBlock(_component_va_sidebar_item, {
                          key: child.id,
                          to: child.to,
                          active: _ctx.isActiveChild(child.id, index),
                          "text-color": "primary",
                          "active-color": "#0039d644",
                          "hover-color": "#0039d622",
                          class: "pl-5"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_va_sidebar_item_content, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_va_sidebar_item_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(child.title), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["to", "active"]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 256))
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ]),
    _: 1
  }, 8, ["width"]))
}