
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import { COLLECTION_TYPES, SECTION_TYPES } from '@/config/enums';
import useValidation from '@/hooks/useValidation';

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    idType: {
      type: String as () => COLLECTION_TYPES,
      required: true,
    },
    type: {
      type: String as () => SECTION_TYPES,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const store = useStore();
    const modal = ref();
    const form = ref();
    const loading = ref(false);
    const name = ref(props.title);

    const hide = () => modal.value?.hide();
    const show = () => modal.value?.show();

    const handleSubmit = () => {
      loading.value = true;

      if (props.idType === COLLECTION_TYPES.RESOURCE) {
        store
          .dispatch('workspaces/renameResourceItem', {
            resourceId: props.id,
            name: name.value,
          })
          .then(() => {
            modal.value?.hide();
          })
          .finally(() => {
            loading.value = false;
          });
      } else {
        store
          .dispatch('workspaces/updateItem', {
            id: props.id,
            title: name.value,
          })
          .then(() => {
            modal.value?.hide();
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };

    return {
      SECTION_TYPES,
      modal,
      form,
      loading,
      name,
      hide,
      show,
      handleSubmit,
      ...useValidation(),
    };
  },
});
