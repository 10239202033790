import aiSearchRoutes from './aisearch/routes';
import authRoutes from './auth/routes';
import calendarRoutes from './calendar/routes';
import clippingsRoutes from './clippings/routes';
import newsRoutes from './news/routes';
import newsletterRoutes from './newsletters/routes';
import privacyPolicyRoutes from './pages/routes';
import searchRoutes from './search/routes';
import settingsRoutes from './settings/routes';

export default {
  routes: [
    ...authRoutes,
    ...calendarRoutes,
    ...clippingsRoutes,
    ...newsRoutes,
    ...newsletterRoutes,
    ...searchRoutes,
    ...aiSearchRoutes,
    ...settingsRoutes,
    ...privacyPolicyRoutes,
  ],
};
