import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row gutter--lg" }
const _hoisted_2 = { class: "flex md6" }
const _hoisted_3 = { class: "display-5 mb-8" }
const _hoisted_4 = {
  key: 0,
  class: "flex md6"
}
const _hoisted_5 = { class: "display-5 mb-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Clippings = _resolveComponent("Clippings")!
  const _component_va_card_content = _resolveComponent("va-card-content")!
  const _component_va_card = _resolveComponent("va-card")!
  const _component_Newsletters = _resolveComponent("Newsletters")!
  const _component_anp_container = _resolveComponent("anp-container")!

  return (_openBlock(), _createBlock(_component_anp_container, { id: "settings" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_va_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_va_card_content, null, {
                default: _withCtx(() => [
                  _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('common.clippings')), 1),
                  _createVNode(_component_Clippings)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        (_ctx.features?.readNewsletters)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_va_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_va_card_content, null, {
                    default: _withCtx(() => [
                      _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('common.newsletters')), 1),
                      _createVNode(_component_Newsletters)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}