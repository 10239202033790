import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14198b47"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row align--center justify--space-between mb-4" }
const _hoisted_2 = { class: "small mr-10" }
const _hoisted_3 = { class: "group__heading" }
const _hoisted_4 = { class: "row align--center justify--end flex--nowrap" }
const _hoisted_5 = { class: "small mr-1" }
const _hoisted_6 = {
  key: 0,
  class: "group__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_checkbox = _resolveComponent("va-checkbox")!
  const _component_va_button = _resolveComponent("va-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_va_checkbox, {
        modelValue: _ctx.selectAll,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectAll) = $event)),
          _ctx.handleChangeSelectAll
        ],
        label: _ctx.$t('common.select-all'),
        class: "group__checkbox"
      }, null, 8, ["modelValue", "label", "onUpdate:modelValue"]),
      _createElementVNode("span", _hoisted_2, _toDisplayString(`(${_ctx.selectedCount}/${_ctx.totalCount})`), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group, title) => {
      return (_openBlock(), _createElementBlock("div", {
        key: title,
        class: "group"
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_va_checkbox, {
            modelValue: _ctx.selectedGroups[title].selected,
            "onUpdate:modelValue": [($event: any) => ((_ctx.selectedGroups[title].selected) = $event), (value) => _ctx.handleChangeGroup(title, value)],
            label: title,
            class: "group__checkbox"
          }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(`(${_ctx.selectedGroups[title].selectedCount}/${_ctx.selectedGroups[title].totalCount})`), 1),
            _createVNode(_component_va_button, {
              icon: _ctx.groupToggleIcons[title],
              color: "dark",
              flat: "",
              onClick: ($event: any) => (_ctx.handleToggleGroup(title))
            }, null, 8, ["icon", "onClick"])
          ])
        ]),
        (_ctx.openState[title])
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.id,
                  class: "item"
                }, [
                  _createVNode(_component_va_checkbox, {
                    modelValue: _ctx.selectedItems[item.id],
                    "onUpdate:modelValue": [($event: any) => ((_ctx.selectedItems[item.id]) = $event), (value) => _ctx.handleChangeItem(item.id, value)],
                    label: item.title,
                    class: "item__checkbox"
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "label"])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}