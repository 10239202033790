import { State as WorkspaceState } from '@/store/modules/workspaces';

import { Resource, Workspace } from '../api/modules/workspaces';

interface MapResourceId {
  workspace: Workspace;
  resource: Resource;
  workspaceIndex: number;
  resourceIndex: number;
}

export default (state: WorkspaceState, resourceId: string): MapResourceId => {
  const workspaceIndex = state.items.findIndex((item) =>
    item.items.find((resource) => resource.resourceId === resourceId),
  );

  const resourceIndex = state.items[workspaceIndex].items.findIndex(
    (item) => item.resourceId === resourceId,
  );

  const workspace = state.items[workspaceIndex];
  const resource = state.items[workspaceIndex].items[resourceIndex];

  return {
    workspace,
    resource,
    workspaceIndex,
    resourceIndex,
  };
};
