import { ActionContext } from 'vuex';

import { OBJECT_KINDS, OBJECT_SCOPES } from '@/config/enums';
import api from '@/services/api';
import { Obj } from '@/services/api/modules/objects';
import { State as R } from '@/store/index';

export interface State {
  items: Obj[];
}

export default {
  namespaced: true,

  state: {
    items: [],
  },

  mutations: {
    setItems(state: State, items: Obj[]): void {
      state.items = items;
    },

    updateItem(state: State, payload: { name: string; value: string }): void {
      const object = state.items.find(
        (item) => item.object[payload.name] !== undefined,
      );

      if (object) object.object[payload.name] = payload.value;
    },
  },

  actions: {
    async fetchItems({ commit }: ActionContext<State, R>): Promise<void> {
      const items = await api.objects.index();
      commit('setItems', items);
    },

    async saveActiveNewsDirectory(
      { commit, state }: ActionContext<State, R>,
      activeNewsDirectory: string,
    ): Promise<void> {
      const object = state.items.find(
        (item) => item.object.activeTab !== undefined,
      );

      const item = {
        id: object?.id,
        kind: OBJECT_KINDS.PREFERENCES,
        scope: OBJECT_SCOPES.PRIVATE,
        object: { activeTab: activeNewsDirectory },
      };

      if (object?.id) {
        await api.objects.update(item);
      } else {
        await api.objects.store(item);
      }

      commit('updateItem', { name: 'activeTab', value: activeNewsDirectory });
    },

    async saveFontSize(
      { commit, state }: ActionContext<State, R>,
      fontSize: string,
    ): Promise<void> {
      const object = state.items.find(
        (item) => item.object.activeTab !== undefined,
      );

      const item = {
        id: object?.id,
        kind: OBJECT_KINDS.PREFERENCES,
        scope: OBJECT_SCOPES.PRIVATE,
        object: { fontsize_desktop: fontSize },
      };

      if (object?.id) {
        await api.objects.update(item);
      } else {
        await api.objects.store(item);
      }

      commit('updateItem', { name: 'fontsize_desktop', value: fontSize });
    },
  },

  getters: {
    fontSize: (state: State): string | undefined =>
      state.items.find((item) => item.object.fontsize_desktop)?.object
        .fontsize_desktop,

    activeNewsDirectory: (state: State): string | undefined => {
      return state.items.find((item) => item.object.activeTab)?.object
        .activeTab;
    },
  },
};
