import { ActionContext } from 'vuex';

import api from '@/services/api';
import { Notification } from '@/services/api/modules/settings';
import { State as R } from '@/store/index';

interface NotificationItem {
  id?: string;
  exists: string;
  enabled: boolean;
  frequency: {
    text: string;
    value: number;
  };
  time: string;
}
export interface Notifications {
  [key: string]: NotificationItem;
}
export interface State {
  notifications: Notification[];
}

export default {
  namespaced: true,

  state: {
    notifications: [],
  },

  mutations: {
    setNotifications(state: State, notifications: Notification[]): void {
      state.notifications = notifications;
    },

    updateNotification(state: State, item: NotificationItem): void {
      const index = state.notifications.findIndex(
        (notification) => notification.id === item.id,
      );

      state.notifications[index] = {
        id: item.id as string,
        notifyFrequency: item.frequency.value,
        notifyTime: item.time,
        notificationKind: ['EMAIL'],
      };
    },

    addNotification(state: State, item: NotificationItem): void {
      state.notifications.push({
        id: item.id as string,
        notifyFrequency: item.frequency.value,
        notifyTime: item.time,
        notificationKind: ['EMAIL'],
      });
    },

    removeNotification(state: State, id: string): void {
      state.notifications = state.notifications.filter(
        (item) => item.id !== id,
      );
    },
  },

  actions: {
    async fetchNotifications({
      commit,
    }: ActionContext<State, R>): Promise<void> {
      const items = await api.settings.notifications();
      commit('setNotifications', items);
    },

    async saveNotifications(
      { commit }: ActionContext<State, R>,
      notifications: Notifications,
    ): Promise<void> {
      const entries = Object.entries(notifications);

      await Promise.all(
        entries.map(async ([id, item]) => {
          if (item.exists && !item.enabled) {
            await api.settings.removeNotification(id);

            commit('removeNotification', id);
          } else if (!item.exists && item.enabled) {
            await api.settings.storeNotification({
              resourceId: id,
              notifyFrequency: item.frequency.value,
              notifyTime: item.time,
            });

            commit('addNotification', { id, ...item });
          } else {
            await api.settings.updateNotification({
              resourceId: id,
              notifyFrequency: item.frequency.value,
              notifyTime: item.time,
            });

            commit('updateNotification', { id, ...item });
          }
        }),
      );
    },
  },
};
