
import { defineComponent } from 'vue';

import ArticleModal from '@/components/modals/ArticleModal/Index.vue';
import { NEWSLETTERS } from '@/config/routes';

export default defineComponent({
  components: {
    ArticleModal,
  },

  setup() {
    return {
      NEWSLETTERS,
    };
  },
});
