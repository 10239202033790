import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_list_item_label = _resolveComponent("va-list-item-label")!
  const _component_va_list_item_section = _resolveComponent("va-list-item-section")!
  const _component_va_list_item = _resolveComponent("va-list-item")!
  const _component_va_list = _resolveComponent("va-list")!
  const _component_va_button_dropdown = _resolveComponent("va-button-dropdown")!
  const _component_anp_popover = _resolveComponent("anp-popover")!

  return (_openBlock(), _createBlock(_component_anp_popover, {
    message: _ctx.$t('common.settings')
  }, {
    default: _withCtx(() => [
      (_ctx.controls)
        ? (_openBlock(), _createBlock(_component_va_button_dropdown, {
            key: 0,
            icon: "settings",
            position: "bottom-end",
            flat: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_va_list, { class: "px-0 py-1 -m-2" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                    return (_openBlock(), _createBlock(_component_va_list_item, {
                      key: item.id,
                      onClick: item.click
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_va_list_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_va_list_item_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.title), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_resolveDynamicComponent(item.modal), _mergeProps({
          key: item.id,
          ref_for: true,
          ref: 
        (element) => {
          _ctx.modals[item.id] = element;
        }
      
        }, item.modalProps), null, 16))
      }), 128))
    ]),
    _: 1
  }, 8, ["message"]))
}