
import { isEqual } from 'lodash';
import { computed, defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import useMessage from '@/hooks/useMessage';
import useValidation from '@/hooks/useValidation';
import { State } from '@/store';

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const store = useStore<State>();
    const { message, setMessage, clearMessage } = useMessage();

    const fontSizeOptions = [
      { text: t('settings.font-size-options.small'), value: 'small' },
      { text: t('settings.font-size-options.medium'), value: 'medium' },
      { text: t('settings.font-size-options.big'), value: 'big' },
    ];

    const initFontSize = computed(() =>
      fontSizeOptions.find(
        (option) => option.value === store.getters['objects/fontSize'],
      ),
    );

    const fontSize = ref(initFontSize.value);

    watch([initFontSize], () => {
      fontSize.value = initFontSize.value;
    });

    const save = () => {
      clearMessage();

      if (!isEqual(fontSize.value, initFontSize.value)) {
        return store
          .dispatch('objects/saveFontSize', fontSize.value?.value)
          .then(setMessage('settings.save-notifications-success'))
          .catch(setMessage());
      }
    };

    return {
      message,
      fontSizeOptions,
      fontSize,
      save,
      ...useValidation(),
    };
  },
});
