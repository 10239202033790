import Base from '@/components/layouts/Base.vue';
import { SETTINGS } from '@/config/routes';

import Index from './views/Index.vue';

export default [
  {
    path: '/instellingen',
    component: Base,
    children: [
      {
        name: SETTINGS,
        path: '',
        component: Index,
      },
    ],
  },
];
