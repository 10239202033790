import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row justify--end mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_anp_label = _resolveComponent("anp-label")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_anp_form = _resolveComponent("anp-form")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createBlock(_component_va_modal, {
    ref: "modal",
    stateful: true,
    title: 
      _ctx.type === _ctx.SECTION_TYPES.CALENDAR
        ? _ctx.$t('calendar.delete-calendar')
        : _ctx.$t('news.delete-directory')
    ,
    "hide-default-actions": true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_anp_form, {
        ref: "form",
        onSubmit: _ctx.handleSubmit
      }, {
        default: _withCtx(() => [
          _createVNode(_component_anp_label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.type === _ctx.SECTION_TYPES.CALENDAR
            ? _ctx.$t('calendar.calendar-name')
            : _ctx.$t('news.directory-name')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_va_input, {
            modelValue: _ctx.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
            rules: [_ctx.required]
          }, null, 8, ["modelValue", "rules"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_va_button, {
              color: "primary",
              class: "mr-2",
              flat: "",
              onClick: _ctx.hide
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('actions.cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_va_button, {
              color: "primary",
              type: "submit",
              loading: _ctx.loading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('actions.save')), 1)
              ]),
              _: 1
            }, 8, ["loading"])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }, 8, ["title"]))
}