
import { groupBy } from 'lodash';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import { Calendar } from '@/services/api/modules/calendars';
import { State } from '@/store';

export default defineComponent({
  props: {
    init: {
      type: Array as () => Calendar[],
      required: false,
    },
  },

  setup() {
    const store = useStore<State>();
    const calendars = computed(() =>
      store.state.calendars.items
        .filter((item) => item.bundleTitle)
        .map((item) => ({ ...item, title: item.name })),
    );
    const groups = groupBy(calendars.value, 'bundleTitle');
    const selection = ref();

    const getSelectedIds = () => {
      return selection.value?.getSelectedIds();
    };

    return {
      groups,
      selection,
      getSelectedIds,
    };
  },
});
