
import getYear from 'date-fns/fp/getYear';
import { computed, defineComponent, onBeforeUnmount, ref } from 'vue';

// Background images
import background1 from '@/assets/images/backgrounds/wp1.jpeg';
import background2 from '@/assets/images/backgrounds/wp2.jpeg';
import background3 from '@/assets/images/backgrounds/wp3.jpeg';
import background4 from '@/assets/images/backgrounds/wp4.jpeg';
import background5 from '@/assets/images/backgrounds/wp5.jpeg';
import background6 from '@/assets/images/backgrounds/wp6.jpeg';
import background7 from '@/assets/images/backgrounds/wp7.jpeg';
import background8 from '@/assets/images/backgrounds/wp8.jpeg';
import background9 from '@/assets/images/backgrounds/wp9.jpeg';
import background10 from '@/assets/images/backgrounds/wp10.jpeg';
import background11 from '@/assets/images/backgrounds/wp11.jpeg';
import background12 from '@/assets/images/backgrounds/wp12.jpeg';
import config from '@/config';
import { PRIVACY_POLICY } from '@/config/routes';

const backgrounds = [
  background1,
  background2,
  background3,
  background4,
  background5,
  background6,
  background7,
  background8,
  background9,
  background10,
  background11,
  background12,
];

export default defineComponent({
  setup() {
    const randomInt = ref(Math.floor(Math.random() * backgrounds.length));

    const year = computed(() => getYear(new Date()));

    const interval = setInterval(function () {
      backgrounds[randomInt.value++ % backgrounds.length];
      if (randomInt.value == backgrounds.length) {
        randomInt.value = 0;
      }
    }, 10000);

    onBeforeUnmount(() => {
      clearInterval(interval);
    });

    return {
      backgrounds,
      randomInt,
      config,
      year,
      PRIVACY_POLICY,
    };
  },
});
