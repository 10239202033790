
import { defineComponent, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { AUTH_LOGIN } from '@/config/routes';
import useMessage from '@/hooks/useMessage';
import useValidation from '@/hooks/useValidation';

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { message, setMessage, clearMessage } = useMessage();
    const form = ref();
    const loading = ref(false);
    const store = useStore();
    const values = reactive({
      email: route.query.email,
      code: route.query.code,
      password: '',
      repeat: '',
    });

    const handleSubmit = () => {
      loading.value = true;
      clearMessage();

      store
        .dispatch('auth/resetPassword', {
          code: values.code,
          email: values.email,
          password: values.password,
        })
        .then(setMessage('auth.create-account-success'))
        .then(() => {
          setTimeout(() => {
            router.push({ name: AUTH_LOGIN });
          }, 4000);
        })
        .catch(setMessage())
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      AUTH_LOGIN,
      message,
      form,
      loading,
      values,
      handleSubmit,
      ...useValidation(),
    };
  },
});
