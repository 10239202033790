
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { LocationAsRelativeRaw, useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { MainItem } from '@/config/navigation/main';
import { State } from '@/store';

export default defineComponent({
  props: {
    items: {
      type: Array as () => MainItem[],
      required: true,
    },
    wide: {
      type: Boolean,
      required: true,
    },
    open: {
      type: Boolean,
      required: false,
    },
  },

  emits: ['openMenuItem'],

  setup(props, { emit }) {
    const store = useStore<State>();
    const router = useRouter();
    const route = useRoute();
    const { locale } = useI18n({});
    if (store.state.auth.user?.preferedLanguage) {
      locale.value = store.state.auth.user.preferedLanguage;
    }

    const isActive = (to: LocationAsRelativeRaw) => {
      const toResolved = router.resolve(to);
      return route.path.startsWith(toResolved.path + '/');
    };

    const openItem = (to: LocationAsRelativeRaw) => {
      if (!props.open || isActive(to)) {
        emit('openMenuItem');
      }
    };

    return {
      isActive,
      openItem,
    };
  },
});
