<template>
  <va-list-item>
    <va-list-item-section>
      <va-progress-circle
        class="loader"
        size="small"
        :thickness="0.2"
        indeterminate
      />
    </va-list-item-section>
  </va-list-item>
</template>

<style lang="scss" scoped>
.loader {
  margin: 0 auto;
}
</style>
