
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import { WORKSPACE_KINDS, WORKSPACE_TYPES } from '@/config/enums';
import useFeatures from '@/hooks/useFeatures';
import useValidation from '@/hooks/useValidation';

export default defineComponent({
  setup() {
    const store = useStore();
    const modal = ref();
    const form = ref();
    const loading = ref();
    const name = ref('');
    const shared = ref(false);

    const hide = () => modal.value?.hide();
    const show = () => modal.value?.show();

    const handleSubmit = () => {
      loading.value = true;

      store
        .dispatch('workspaces/saveItem', {
          title: name.value,
          wsKind: WORKSPACE_KINDS.CALENDARS,
          wsType: shared.value
            ? WORKSPACE_TYPES.SHARED
            : WORKSPACE_TYPES.PRIVATE,
        })
        .then(() => {
          modal.value?.hide();
          name.value = '';
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      modal,
      form,
      loading,
      name,
      shared,
      hide,
      show,
      handleSubmit,
      ...useValidation(),
      ...useFeatures(),
    };
  },
});
