import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_alert = _resolveComponent("va-alert")!
  const _component_anp_selection = _resolveComponent("anp-selection")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_CreateNewsletter = _resolveComponent("CreateNewsletter")!
  const _component_DeleteClippings = _resolveComponent("DeleteClippings")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showError)
      ? (_openBlock(), _createBlock(_component_va_alert, {
          key: 0,
          color: "danger",
          border: "left",
          class: "mb-6"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('clippings.no-clippings-selected')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.clippedItems.length)
      ? (_openBlock(), _createBlock(_component_va_alert, {
          key: 1,
          color: "info",
          border: "left"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('clippings.no-clippings')), 1)
          ]),
          _: 1
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (_ctx.groups && _ctx.init?.length)
            ? (_openBlock(), _createBlock(_component_anp_selection, {
                key: 0,
                ref: "selection",
                groups: _ctx.groups,
                init: _ctx.init,
                initValue: false
              }, null, 8, ["groups", "init"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_1, [
            (_ctx.features?.createNewsletter)
              ? (_openBlock(), _createBlock(_component_va_button, {
                  key: 0,
                  block: "",
                  onClick: _ctx.showCreate
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('clippings.create-newsletter')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_va_button, {
              class: _normalizeClass(_ctx.features?.createNewsletter && 'mt-2'),
              color: "danger",
              block: "",
              outline: "",
              onClick: _ctx.showDelete
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('clippings.delete-clippings')), 1)
              ]),
              _: 1
            }, 8, ["class", "onClick"])
          ])
        ], 64)),
    _createVNode(_component_CreateNewsletter, {
      ref: "createModal",
      clippingsIds: _ctx.selectedItems
    }, null, 8, ["clippingsIds"]),
    _createVNode(_component_DeleteClippings, {
      ref: "deleteModal",
      clippingsIds: _ctx.selectedItems
    }, null, 8, ["clippingsIds"])
  ], 64))
}