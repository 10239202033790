import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row justify--space-between align--center mb-6" }
const _hoisted_2 = { class: "display-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Help = _resolveComponent("Help")!
  const _component_Form = _resolveComponent("Form")!
  const _component_anp_container = _resolveComponent("anp-container")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_anp_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_Form, null, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('common.search')), 1),
              _createVNode(_component_Help)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_router_view)
  ], 64))
}