/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import config from '@/config';
import { AUTH_LOGIN } from '@/config/routes';
import router, { checkIsPublic } from '@/plugins/router';
import store from '@/store';

interface Body {
  [key: string]: string | string[];
}

const generateUri = (...segments: string[]) => {
  const base = store.state.global.api.uri;
  const parts = [base, ...segments];

  return parts.map((part) => part.replace(/^\/+|\/+$/g, '')).join('/');
};

const urlEncodeBody = (body?: Body) => {
  if (!body) return body;

  return Object.entries(body).reduce((accumulator, [key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item: string) => {
        accumulator.append(key, item);
      });
    } else if (typeof value === 'number') {
      accumulator.append(key, String(value));
    } else if (typeof value === 'object') {
      accumulator.append(key, JSON.stringify(value));
    } else if (typeof value === 'string') {
      accumulator.append(key, value);
    } else if (value) {
      accumulator.append(key, value);
    }

    return accumulator;
  }, new URLSearchParams());
};

const call = async (
  type: string,
  path: string,
  body?: Body,
  overrideOptions?: RequestInit,
): Promise<any> => {
  const uri = generateUri(path);

  const options: RequestInit = {
    ...overrideOptions,
    method: type,
    body: urlEncodeBody(body),
    credentials: 'include',
    headers: {
      appid: config.api.id,
      'api-version': config.api.version,
      'content-type': 'application/x-www-form-urlencoded',
      accept: 'application/json',
      ...overrideOptions?.headers,
    },
  };

  const response = await fetch(uri, options);

  if (!response || !response.ok) {
    if (
      response.status === 401 &&
      !response.url.endsWith('/sources') &&
      !response.url.endsWith('/calendars')
    ) {
      await store.commit('auth/reset');

      if (!checkIsPublic(router.currentRoute.value)) {
        router.push({ name: AUTH_LOGIN });
      }
    }

    return Promise.reject({ response });
  }

  const json = await response.json();

  if (json.hasError) {
    return Promise.reject({ response, json });
  }

  return json.data;
};

const get = (path: string, options?: RequestInit): Promise<any> =>
  call('GET', path, undefined, options);

const post = (path: string, body: any, options?: RequestInit): Promise<any> =>
  call('POST', path, body, options);

const put = (path: string, body: any, options?: RequestInit): Promise<any> =>
  call('PUT', path, body, options);

const del = (path: string, options?: RequestInit): Promise<any> =>
  call('DELETE', path, undefined, options);

export default { get, post, put, del };
