
import { computed, defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import Draggable from 'vuedraggable';
import { useStore } from 'vuex';

import Column from '@/modules/news/components/Column.vue';
import { Resource, Workspace } from '@/services/api/modules/workspaces';
import { State } from '@/store';

type Event = {
  [key in 'moved']: {
    element: Resource;
    newIndex: number;
    oldIndex: number;
  };
};

export default defineComponent({
  components: {
    Draggable,
    Column,
  },

  setup() {
    const route = useRoute();
    const store = useStore<State>();
    const dragging = ref(false);

    const current = computed<Workspace>(() =>
      store.getters['workspaces/getItem'](route.params.id),
    );
    const workspace = ref(current.value);

    watch(current, () => {
      workspace.value = current.value;
    });

    const handleChangeResource = (event: Event) => {
      if (event.moved) {
        const { newIndex, oldIndex = 0, element } = event.moved;
        const { resourceId } = element as Resource;
        const addOne = newIndex > oldIndex;
        const order = addOne ? newIndex + 1 : newIndex;

        store.dispatch('workspaces/updateResourceItem', {
          resourceId,
          order,
        });
      }
    };

    return {
      dragging,
      workspace,
      handleChangeResource,
    };
  },
});
