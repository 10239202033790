import i18n from '@/plugins/i18n';

import { AUTH_LOGIN } from '../routes';

const renderPrivacy = (path: string): boolean =>
  path.startsWith('/pages/privacy-policy');

export default [
  {
    id: 'back-to-app',
    title: i18n.global.t('common.back-to-app'),
    to: { name: AUTH_LOGIN },
    render: renderPrivacy,
  },
];
