import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_chip = _resolveComponent("va-chip")!
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.data)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: "#",
        class: "live-agenda",
        onClick: _ctx.handleClick
      }, {
        default: _withCtx(() => [
          _createVNode(_component_va_chip, {
            class: "chip",
            size: "small",
            color: "white"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.resultCount), 1)
            ]),
            _: 1
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('news.live-agenda')) + " " + _toDisplayString(_ctx.data.title) + " ", 1),
          _createVNode(_component_va_icon, {
            class: "ml-auto",
            name: "arrow_forward"
          })
        ]),
        _: 1
      }, 8, ["onClick"]))
    : _createCommentVNode("", true)
}