import { Searches } from '@/hooks/useSearch';
import mapEvents from '@/services/mapping/mapEvents';

import http from '../http';
import listOptions, { ListOptions } from '../listOptions';
import {
  Article,
  ArticleList,
  ArticleMail,
  ArticleMedia,
  ArticleRelated,
} from './article.types';
import { SearchQuery } from './search';

export interface NewsletterContent {
  id: string;
  name: string;
  queryText?: string;
  queryTitle?: string;
  queryAuthor?: string;
  period: string;
  fromDate?: string;
  toDate?: string;
  sources?: string[];
  calendars?: string[];
  sourceKinds?: string[];
}

export interface SectionOptions {
  id: string;
  title: string;
}

export interface SectionsResults {
  resultCount: number;
  items: SectionOptions[];
}

const show = (id: string): Promise<NewsletterContent> => {
  return http.get(`/newslettercontent/${id}`);
};

const items = async (
  id: string,
  options: ListOptions,
): Promise<ArticleList> => {
  const params = listOptions(options);
  const data = await http.get(`/newslettercontent/${id}/items${params}`);

  return {
    ...data,
    items: mapEvents(data.items),
  };
};

const article = (
  resourceId: string,
  id: string,
  options?: ListOptions,
): Promise<Article> => {
  const params = listOptions(options);
  if (options?.searchColumn) {
    return http.get(
      `/newslettercontent/${resourceId}/items/searchresult/${id}${params}`,
    );
  } else {
    return http.get(`/newslettercontent/${resourceId}/items/${id}${params}`);
  }
};

const articleMedia = (
  resourceId: string,
  id: string,
): Promise<ArticleMedia[]> => {
  return http.get(`/newslettercontent/${resourceId}/items/${id}/media`);
};

const articleRelated = (
  resourceId: string,
  id: string,
  options: ListOptions,
): Promise<ArticleRelated> => {
  const params = listOptions(options);
  return http.get(
    `/newslettercontent/${resourceId}/items/${id}/related${params}`,
  );
};

const articleMail = (item: ArticleMail): Promise<undefined> => {
  return http.post(
    `/newslettercontent/${item.resourceId}/items/${item.id}/mail`,
    {
      username: item.email,
      subject: item.subject,
      comment: item.message,
    },
  );
};

const search = async (
  item: SearchQuery,
  options: ListOptions,
): Promise<ArticleList> => {
  const params = listOptions(options);
  const data = await http.post(
    `/newslettercontent/${item.id}/items/searchresult${params}`,
    item,
  );

  return {
    ...data,
    items: mapEvents(data.items),
  };
};

const sections = async (itemId: string): Promise<SectionsResults> => {
  const data = await http.get(`/newslettercontent/${itemId}/context/sections`);
  return {
    ...data,
  };
};

const searchesRes = async (
  searchesObject: Searches,
  options: ListOptions,
): Promise<ArticleList> => {
  const params = listOptions(options);
  const data = await http.get(
    `/newslettercontent/${searchesObject.itemId}/context/sections/${searchesObject.searchId}/items${params}`,
  );
  return {
    ...data,
    items: mapEvents(data.items),
  };
};

export default {
  show,
  items,
  article,
  articleMedia,
  articleRelated,
  articleMail,
  search,
  sections,
  searchesRes,
};
