import { eachDayOfInterval } from 'date-fns';

import { ArticleListItem } from '../api/modules/article.types';

export default (items: ArticleListItem[]): ArticleListItem[] => {
  return items
    .map((item) => {
      if (!item.eventStart || !item.eventEnd) {
        return item;
      }

      const start = new Date(item.eventStart);
      const end = new Date(item.eventEnd);

      return {
        ...item,
        eventStart: start,
        eventEnd: end,
        dayRange: eachDayOfInterval({
          start: start,
          end: end,
        }),
      };
    })
    .sort(
      (a, b) => (a.eventStart?.getTime() || 0) - (b.eventStart?.getTime() || 0),
    );
};
