
import { computed, defineComponent, ref } from 'vue';

import { CALENDAR_ARTICLE } from '@/config/routes';
import useSearch from '@/hooks/useSearch';
import Settings from '@/modules/calendar/components/Settings.vue';
import api from '@/services/api';
import { ArticleListItem } from '@/services/api/modules/article.types';
import { Resource, Workspace } from '@/services/api/modules/workspaces';

export default defineComponent({
  components: {
    Settings,
  },
  props: {
    resourceId: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array as () => ArticleListItem[],
      required: false,
    },
    resource: {
      type: Object as () => Resource,
    },
    workspace: {
      type: Object as () => Workspace,
      required: true,
    },
  },

  setup(props) {
    const reload = ref(false);

    const apiModule = computed(() => {
      const query = isNaN(Number(props.resourceId));
      return query ? api.queries : api.calendars;
    });

    const handleReload = () => {
      reload.value = true;
    };

    const search = useSearch(props.resourceId, apiModule.value);

    const renderItems = computed(() =>
      search.showSearch.value ? search.searchItems.value : props.items,
    );

    const renderItemIds = computed(() =>
      renderItems.value?.map((item) => item.id),
    );

    const isEmpty = computed(() =>
      search.showSearch.value
        ? search.searchEmpty.value
        : !props.isLoading && !props.items?.length,
    );

    return {
      CALENDAR_ARTICLE,
      ...search,
      renderItems,
      renderItemIds,
      isEmpty,
      handleReload,
    };
  },
});
