
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import { AUTH_LOGIN } from '@/config/routes';
import useMessage from '@/hooks/useMessage';
import useValidation from '@/hooks/useValidation';

export default defineComponent({
  setup() {
    const { message, setMessage, clearMessage } = useMessage();
    const form = ref();
    const loading = ref(false);
    const email = ref('');
    const store = useStore();

    const handleSubmit = () => {
      loading.value = true;
      clearMessage();

      store
        .dispatch('auth/forgotPassword', { email: email.value })
        .then(setMessage('auth.forgot-password-success'))
        .catch(setMessage())
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      AUTH_LOGIN,
      message,
      form,
      loading,
      email,
      handleSubmit,
      ...useValidation(),
    };
  },
});
