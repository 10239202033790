import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_anp_selection = _resolveComponent("anp-selection")!

  return (_openBlock(), _createBlock(_component_anp_selection, {
    ref: "selection",
    groups: _ctx.groups,
    init: _ctx.init
  }, null, 8, ["groups", "init"]))
}