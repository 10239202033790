import { ActionContext } from 'vuex';

import api from '@/services/api';
import { Calendar } from '@/services/api/modules/calendars';
import { State as R } from '@/store/index';

export interface State {
  items: Calendar[];
}

export default {
  namespaced: true,

  state: {
    items: [],
  },

  mutations: {
    setItems(state: State, items: Calendar[]): void {
      state.items = items;
    },
  },

  actions: {
    async fetchItems({ commit }: ActionContext<State, R>): Promise<void> {
      const items = await api.calendars.index();
      commit('setItems', items);
    },
  },

  getters: {},
};
