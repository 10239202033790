/* eslint-disable @typescript-eslint/no-explicit-any */
import { upperFirst } from 'lodash';
import { Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { MESSAGE_TYPES } from '@/config/enums';

const IS_TRANSLATION = /[a-z-]*\.([a-z-]\.?)*/;

export interface Message {
  text: string;
  type: MESSAGE_TYPES;
}

interface UseMessage {
  message: Ref<Message | undefined>;
  setMessage: (value?: string | undefined) => (error: APIError) => void;
  clearMessage: () => void;
}

interface APIError {
  response: Response;
  json?: any;
}

export default (): UseMessage => {
  const { t } = useI18n();
  const message = ref<Message | undefined>(undefined);

  const setMessage =
    (value: string | undefined = undefined) =>
    (error: APIError) => {
      if (!value) {
        if (error.response.status === 401) {
          message.value = {
            text: t('error.credentials'),
            type: MESSAGE_TYPES.DANGER,
          };
        } else if (error.json?.data?.fields) {
          const [first] = error.json.data.fields;

          message.value = {
            text: upperFirst(first.msg),
            type: MESSAGE_TYPES.DANGER,
          };
        } else if (error.json?.data?.description) {
          message.value = {
            text: upperFirst(error.json?.data?.description),
            type: MESSAGE_TYPES.DANGER,
          };
        } else {
          message.value = {
            text: t('error.something'),
            type: MESSAGE_TYPES.DANGER,
          };
        }
      } else if (IS_TRANSLATION.test(value)) {
        message.value = {
          text: t(value),
          type: MESSAGE_TYPES.SUCCESS,
        };
      } else {
        message.value = {
          text: value,
          type: MESSAGE_TYPES.SUCCESS,
        };
      }
    };

  const clearMessage = () => {
    if (message.value) {
      message.value = undefined;
    }
  };

  return {
    message,
    setMessage,
    clearMessage,
  };
};
