import { CONTEXT_TYPES } from '@/config/enums';
import { Searches } from '@/hooks/useSearch';
import mapEvents from '@/services/mapping/mapEvents';

import http from '../http';
import listOptions, { ListOptions } from '../listOptions';
import {
  Article,
  ArticleList,
  ArticleMail,
  ArticleMedia,
  ArticleRelated,
} from './article.types';
import { SearchQuery } from './search';

export interface Source {
  id: string;
  title: string;
  bundleTitle: string;
  articleKind: string;
  searchKind: string;
}

export interface SearchesOptions {
  id: string;
  title: string;
}

export interface TranslateOptions {
  sourceId: string;
  itemId: string;
}

export interface Drop {
  text: string;
  value: string;
}

export interface SearchesResults {
  resultCount: number;
  title: string;
  items: SearchesOptions[];
}

const index = (): Promise<Source[]> => {
  return http.get('/sources');
};

const items = async (
  id: string,
  options: ListOptions,
): Promise<ArticleList> => {
  const params = listOptions(options);
  const data = await http.get(`/sources/${id}/items${params}`);

  return {
    ...data,
    items: mapEvents(data.items),
  };
};

const article = (
  resourceId: string,
  id: string,
  options?: ListOptions,
): Promise<Article> => {
  const params = listOptions(options);
  if (options?.highlight) {
    return http.get(`/sources/${resourceId}/items/searchresult/${id}${params}`);
  } else {
    return http.get(`/sources/${resourceId}/items/${id}${params}`);
  }
};

const articleMedia = (
  resourceId: string,
  id: string,
): Promise<ArticleMedia[]> => {
  return http.get(`/sources/${resourceId}/items/${id}/media`);
};

const articleRelated = (
  resourceId: string,
  id: string,
  options: ListOptions,
): Promise<ArticleRelated> => {
  const params = listOptions(options);
  return http.get(`/sources/${resourceId}/items/${id}/related${params}`);
};

const articleMail = (item: ArticleMail): Promise<undefined> => {
  return http.post(`/sources/${item.resourceId}/items/${item.id}/mail`, {
    username: item.email,
    subject: item.subject,
    comment: item.message,
  });
};

const context = async (
  id: string,
  type: CONTEXT_TYPES,
): Promise<ArticleList> => {
  const params = listOptions();
  const data = await http.get(`/sources/${id}/context/${type}${params}`);

  return {
    ...data,
    items: mapEvents(data.items),
  };
};

const search = async (
  item: SearchQuery,
  options: ListOptions,
): Promise<ArticleList> => {
  const params = listOptions(options);
  const data = await http.post(
    `/sources/${item.id}/items/searchresult${params}`,
    item,
  );

  return {
    ...data,
    items: mapEvents(data.items),
  };
};

const searches = async (itemId: string): Promise<SearchesResults> => {
  const data = await http.get(`/sources/${itemId}/context/searches`);
  return {
    ...data,
  };
};

const expertRegions = async (itemId: string): Promise<SearchesResults> => {
  const data = await http.get(`/sources/${itemId}/context/regions`);
  return {
    ...data,
  };
};

const searchesRes = async (
  searchesObject: Searches,
  options: ListOptions,
): Promise<ArticleList> => {
  const params = listOptions(options);
  const data = await http.get(
    `/sources/${searchesObject.itemId}/context/${searchesObject.context}/${searchesObject.searchId}/items${params}`,
  );
  return {
    ...data,
    items: mapEvents(data.items),
  };
};

const translateBody = async (item: TranslateOptions): Promise<string> => {
  const data = await http.get(
    `/sources/${item.sourceId}/items/${item.itemId}/translatebody`,
  );
  return <string>data;
};

const originalBody = async (item: TranslateOptions): Promise<string> => {
  const data = await http.get(
    `/sources/${item.sourceId}/items/${item.itemId}/originalbody`,
  );
  return <string>data;
};

export default {
  index,
  items,
  article,
  articleMedia,
  articleRelated,
  articleMail,
  context,
  search,
  searches,
  searchesRes,
  expertRegions,
  translateBody,
  originalBody,
};
