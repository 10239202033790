
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['click'],

  setup(props, { emit }) {
    const handleClick = (event: Event) => emit('click', event);

    return {
      handleClick,
    };
  },
});
