
import { computed, defineComponent, ref } from 'vue';
import { useQuery } from 'vue-query';
import { useRouter } from 'vue-router';

import { SEARCH } from '@/config/routes';
import api from '@/services/api';
import { Article } from '@/services/api/modules/article.types';

export default defineComponent({
  props: {
    article: {
      type: Object as () => Article,
      required: true,
    },
    showScore: Boolean,
    vectorizer: String,
  },

  setup(props) {
    const showMoreSources = ref(false);
    const router = useRouter();

    const fetchTopics = () => {
      return api.search.articleMediaTopics(props.article.id, {
        count: 5,
        vectorizer: props.vectorizer,
      });
    };

    const query = useQuery(
      [
        'article-media-topics',
        { articleId: props.article.id, vectorizer: props.vectorizer },
      ],
      fetchTopics,
    );

    const filteredSources = computed(() => query.data.value);

    const handleClick = (tag: string) => {
      router.push({ name: SEARCH, params: { query: 'iptc:' + tag } });
    };

    return {
      showMoreSources,
      filteredSources,
      handleClick,
    };
  },
});
