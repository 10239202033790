
import { cloneDeep, isEqual } from 'lodash';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import useFeatures from '@/hooks/useFeatures';
import useMessage from '@/hooks/useMessage';
import useValidation from '@/hooks/useValidation';
import { Collection } from '@/services/api/modules/collections';
import { Resource } from '@/services/api/modules/workspaces';
import { State } from '@/store';
import { Notifications } from '@/store/modules/settings';

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const store = useStore<State>();
    const { message, setMessage, clearMessage } = useMessage();

    const frequencyOptions = [
      { text: t('settings.frequency-options.direct'), value: 0 },
      { text: t('settings.frequency-options.hour'), value: 60 },
      { text: t('settings.frequency-options.2-hours'), value: 120 },
      { text: t('settings.frequency-options.4-hours'), value: 240 },
      { text: t('settings.frequency-options.day'), value: 1440 },
    ];

    const timeOptions = computed(() => {
      let result = Array<string>();
      for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 60; j += 15) {
          result.push(
            i.toString().padStart(2, '0') + ':' + j.toString().padStart(2, '0'),
          );
        }
      }
      return result;
    });

    onMounted(() => {
      store.dispatch('settings/fetchNotifications');
      if (useFeatures().features.value?.notificationsFor360) {
        store.dispatch('collections/fetchPublishQueryCollections');
      }
    });

    const resources = computed<Resource[]>(
      () => store.getters['workspaces/resourcesWithoutNewsletterContent'],
    );

    const resources360 = computed<Collection[]>(
      () => store.getters['collections/publishQueryCollections'],
    );

    const notifications = computed(() =>
      store.state.settings.notifications.filter((item) =>
        item.notificationKind.includes('EMAIL'),
      ),
    );

    const initValues = computed<Notifications>(() =>
      resources.value.reduce((accumulator, resource) => {
        const current = notifications.value.find(
          (item) => item.id === resource.resourceId,
        );

        return {
          ...accumulator,
          [resource.resourceId]: {
            exists: current !== undefined,
            enabled: current !== undefined,
            time: current?.notifyTime,
            frequency: frequencyOptions.find(
              (option) => option.value === (current?.notifyFrequency || 0),
            ),
          },
        };
      }, {}),
    );

    const initValues360 = computed<Notifications>(() =>
      resources360.value.reduce((accumulator, resource) => {
        const current = notifications.value.find(
          (item) => item.id === resource.publishqueryId,
        );

        return {
          ...accumulator,
          [resource.publishqueryId ? resource.publishqueryId : resource.id]: {
            exists: current !== undefined,
            enabled: current !== undefined,
            time: current?.notifyTime,
            frequency: frequencyOptions.find(
              (option) => option.value === (current?.notifyFrequency || 0),
            ),
          },
        };
      }, {}),
    );

    const values = ref(cloneDeep(initValues.value));

    const values360 = ref(cloneDeep(initValues360.value));

    watch(initValues, () => {
      values.value = cloneDeep(initValues.value);
    });

    watch(initValues360, () => {
      values360.value = cloneDeep(initValues360.value);
    });

    const save = () => {
      clearMessage();

      if (!isEqual(values.value, initValues.value)) {
        var changed = Object.fromEntries(
          Object.entries(values.value).filter(
            ([id, notification]) =>
              !isEqual(initValues.value[id], notification),
          ),
        );

        if (!isEqual(values360.value, initValues360.value)) {
          const changed360 = Object.fromEntries(
            Object.entries(values360.value)
              .filter(
                ([id, notification]) =>
                  !isEqual(initValues360.value[id], notification),
              )
              .concat(
                Object.entries(values.value).filter(
                  ([id, notification]) =>
                    !isEqual(initValues.value[id], notification),
                ),
              ),
          );
          changed = changed360;
        }

        return store
          .dispatch('settings/saveNotifications', changed)
          .then(setMessage('settings.save-notifications-success'))
          .catch(setMessage());
      } else {
        if (!isEqual(values360.value, initValues360.value)) {
          const changed = Object.fromEntries(
            Object.entries(values360.value).filter(
              ([id, notification]) =>
                !isEqual(initValues360.value[id], notification),
            ),
          );

          return store
            .dispatch('settings/saveNotifications', changed)
            .then(setMessage('settings.save-notifications-success'))
            .catch(setMessage());
        }
      }
    };

    return {
      message,
      frequencyOptions,
      timeOptions,
      values,
      values360,
      resources,
      resources360,
      save,
      ...useValidation(),
      ...useFeatures(),
    };
  },
});
