import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a375c7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "entities" }
const _hoisted_2 = { class: "display-6" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_chip = _resolveComponent("va-chip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('common.quotedSources')), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredSources, (entity, index) => {
      return (_openBlock(), _createBlock(_component_va_chip, {
        key: index,
        outline: "",
        size: "small",
        onClick: ($event: any) => (_ctx.handleClick(entity.value))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(entity.value), 1)
        ]),
        _: 2
      }, 1032, ["onClick"]))
    }), 128)),
    (!_ctx.showMoreSources && _ctx.article.quotedSources?.length > 5)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_va_chip, {
            flat: "",
            size: "small",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMoreSources = true))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.show-more')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}