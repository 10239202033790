
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import { WORKSPACE_KINDS, WORKSPACE_TYPES } from '@/config/enums';
import useValidation from '@/hooks/useValidation';
import { Resource, Workspace } from '@/services/api/modules/workspaces';
import { State } from '@/store';

export default defineComponent({
  props: {
    resource: {
      type: Object as () => Resource,
      required: true,
    },
    workspaceType: {
      type: String as () => WORKSPACE_TYPES,
      validator: (value: WORKSPACE_TYPES) =>
        Object.values(WORKSPACE_TYPES).includes(value),
      required: true,
    },
  },

  setup(props) {
    const store = useStore<State>();

    const accessTypes = computed(() => {
      const features = store.state.auth.user?.features;
      const types = [WORKSPACE_TYPES.PRIVATE];

      if (features?.manageSharedWorkspaces) {
        types.push(WORKSPACE_TYPES.SHARED);
      }

      return types;
    });

    const hasAccess = computed(() =>
      accessTypes.value.includes(props.workspaceType),
    );

    const workspaceOptions = computed(() => {
      const items = store.getters['workspaces/getItems'] as Workspace[];

      return items
        ?.filter(
          (item) =>
            item.wsKind === WORKSPACE_KINDS.COLUMN &&
            accessTypes.value?.includes(item.wsType),
        )
        .map((item) => ({ text: item.title, value: item.id }));
    });

    const workspace = ref(workspaceOptions.value[0]);
    const modal = ref();
    const loading = ref(false);

    const hide = () => modal.value?.hide();
    const show = () => modal.value?.show();

    const handleSubmit = () => {
      loading.value = true;

      store
        .dispatch('workspaces/duplicateResourceItem', {
          id: !hasAccess.value ? workspace.value.value : undefined,
          resourceId: props.resource.resourceId,
        })
        .then(() => {
          modal.value?.hide();
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      hasAccess,
      accessTypes,
      workspaceOptions,
      workspace,
      modal,
      loading,
      hide,
      show,
      handleSubmit,
      ...useValidation(),
    };
  },
});
