
import { random } from 'lodash';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    date: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: Boolean,
      default: true,
    },
    text: {
      type: Boolean,
      default: false,
    },
    meta: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return { random };
  },
});
