import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-686906bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar-settings-item" }
const _hoisted_2 = { class: "sidebar-settings-item__title" }
const _hoisted_3 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_button = _resolveComponent("va-button")!
  const _component_anp_popover = _resolveComponent("anp-popover")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_Empty = _resolveComponent("Empty")!
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_va_switch = _resolveComponent("va-switch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.item.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_anp_popover, {
        message: _ctx.$t('actions.edit')
      }, {
        default: _withCtx(() => [
          (_ctx.editable)
            ? (_openBlock(), _createBlock(_component_va_button, {
                key: 0,
                icon: "edit",
                color: "primary",
                size: "small",
                flat: "",
                onClick: _ctx.showEdit
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["message"]),
      _createVNode(_component_anp_popover, {
        message: _ctx.$t('actions.delete')
      }, {
        default: _withCtx(() => [
          (_ctx.editable)
            ? (_openBlock(), _createBlock(_component_va_button, {
                key: 0,
                class: "ml-1",
                icon: "delete",
                color: "danger",
                size: "small",
                flat: "",
                onClick: _ctx.showDelete
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["message"]),
      _createVNode(_component_Delete, {
        ref: "deleteModal",
        id: _ctx.id,
        idType: _ctx.idType,
        type: _ctx.type,
        "resource-kind": _ctx.resourceKind
      }, null, 8, ["id", "idType", "type", "resource-kind"]),
      _createVNode(_component_Edit, {
        ref: "editModal",
        title: _ctx.item.title,
        id: _ctx.id,
        idType: _ctx.idType,
        type: _ctx.type
      }, null, 8, ["title", "id", "idType", "type"]),
      _createVNode(_component_Empty, {
        ref: "emptyModal",
        type: _ctx.type
      }, null, 8, ["type"]),
      _createVNode(_component_anp_popover, {
        message: _ctx.$t('actions.drag')
      }, {
        default: _withCtx(() => [
          (_ctx.draggable)
            ? (_openBlock(), _createBlock(_component_va_icon, {
                key: 0,
                class: "actions__handle handle ml-3",
                name: "drag_handle",
                color: "gray"
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["message"]),
      (_ctx.toggleable)
        ? (_openBlock(), _createBlock(_component_va_switch, {
            key: 0,
            class: "ml-3",
            modelValue: _ctx.visible,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
            size: "small"
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ])
  ]))
}