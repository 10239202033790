
import { defineComponent, ref } from 'vue';

export default defineComponent({
  emits: ['submit'],

  setup(props, { emit }) {
    const form = ref();

    const handleSubmit = () => {
      const valid = form.value?.validate();
      if (valid) emit('submit');
    };

    return {
      form,
      handleSubmit,
    };
  },
});
