import Base from '@/components/layouts/Base.vue';
import { NEWS, NEWS_ARTICLE } from '@/config/routes';
import defaultRoute from '@/services/router/defaultRoute';

import Article from './views/Article.vue';
import Index from './views/Index.vue';

export default [
  {
    path: '/nieuws',
    component: Base,
    children: [
      {
        name: NEWS,
        path: ':id?',
        component: Index,
        children: [
          {
            name: NEWS_ARTICLE,
            path: 'artikel/:resourceId/:articleId',
            component: Article,
          },
        ],
      },
    ],
  },
  {
    path: '/:wildcard(.*)',
    redirect: { name: defaultRoute() },
  },
];
