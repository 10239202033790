import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row justify--end mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_anp_label = _resolveComponent("anp-label")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_va_radio = _resolveComponent("va-radio")!
  const _component_va_checkbox = _resolveComponent("va-checkbox")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_anp_form = _resolveComponent("anp-form")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createBlock(_component_va_modal, {
    ref: "modal",
    stateful: true,
    title: _ctx.$t('news.news-feed-settings'),
    "hide-default-actions": true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_anp_form, {
        ref: "form",
        onSubmit: _ctx.handleSubmit
      }, {
        default: _withCtx(() => [
          (_ctx.isEditable && _ctx.isPrivate)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_anp_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('news.news-feed-name')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_va_input, {
                  modelValue: _ctx.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                  rules: [_ctx.required]
                }, null, 8, ["modelValue", "rules"])
              ], 64))
            : _createCommentVNode("", true),
          _createVNode(_component_anp_label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.display')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", null, [
            _createVNode(_component_va_radio, {
              modelValue: _ctx.display,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.display) = $event)),
              option: _ctx.DISPLAY_TYPES.DEFAULT,
              label: _ctx.$t('common.default')
            }, null, 8, ["modelValue", "option", "label"]),
            _createVNode(_component_va_radio, {
              modelValue: _ctx.display,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.display) = $event)),
              option: _ctx.DISPLAY_TYPES.COMPACT,
              label: _ctx.$t('common.compact')
            }, null, 8, ["modelValue", "option", "label"]),
            (_ctx.isQuery)
              ? (_openBlock(), _createBlock(_component_va_radio, {
                  key: 0,
                  modelValue: _ctx.display,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.display) = $event)),
                  option: _ctx.DISPLAY_TYPES.SNIPPET,
                  label: _ctx.$t('common.snippet')
                }, null, 8, ["modelValue", "option", "label"]))
              : _createCommentVNode("", true),
            _createVNode(_component_va_radio, {
              modelValue: _ctx.display,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.display) = $event)),
              option: _ctx.DISPLAY_TYPES.EXTENDED,
              label: _ctx.$t('common.extended')
            }, null, 8, ["modelValue", "option", "label"])
          ]),
          (!_ctx.isQuery)
            ? (_openBlock(), _createBlock(_component_va_checkbox, {
                key: 1,
                modelValue: _ctx.images,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.images) = $event)),
                disabled: _ctx.display === _ctx.DISPLAY_TYPES.COMPACT,
                label: _ctx.$t('common.display-images'),
                class: "mt-4"
              }, null, 8, ["modelValue", "disabled", "label"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_va_button, {
              color: "primary",
              class: "mr-2",
              flat: "",
              onClick: _ctx.hide
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('actions.cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_va_button, {
              color: "primary",
              type: "submit",
              loading: _ctx.loading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('actions.save')), 1)
              ]),
              _: 1
            }, 8, ["loading"])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }, 8, ["title"]))
}