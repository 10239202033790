import { ActionContext } from 'vuex';

import config from '@/config';
import environment from '@/services/environment';
import { State as R } from '@/store/index';

export interface State {
  api: {
    uri: string;
  };
}

export default {
  namespaced: true,

  state: {
    api: {
      uri: undefined,
    },
  },

  mutations: {
    setApiEndpoint(state: State, endpoint: string): void {
      state.api.uri = endpoint;
    },
  },

  actions: {
    async fetchApiEndpoint({ commit }: ActionContext<State, R>): Promise<void> {
      try {
        const request = `/services/settings.json?path=${environment()}`;
        const response = await fetch(request);
        const result = await response.json();

        commit('setApiEndpoint', result.api_endpoint);
      } catch (e) {
        commit('setApiEndpoint', config.api.uri);
      }
    },
  },

  getters: {},
};
