import { computed, onMounted, onUnmounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { AUTH_LOGIN } from '@/config/routes';
import router from '@/plugins/router';
import defaultRoute from '@/services/router/defaultRoute';
import { State } from '@/store';

export default (): void => {
  const route = useRoute();
  const store = useStore<State>();

  const authenticated = computed(() => store.state.auth.authenticated);

  const handleFocus = () => {
    store.dispatch('auth/check');
  };

  const fetch = async () => {
    store.dispatch('auth/fetchUser');
    store.dispatch('calendars/fetchItems');
    store.dispatch('collections/fetchItems');
    store.dispatch('sources/fetchItems');

    await Promise.all([
      store.dispatch('workspaces/fetchItems'),
      store.dispatch('objects/fetchItems'),
    ]);

    if (route.path.startsWith('/auth')) {
      router.replace({ name: defaultRoute() });
    }

    window.addEventListener('focus', handleFocus);
  };

  onMounted(async () => {
    await store.dispatch('global/fetchApiEndpoint');
    const auth = await store.dispatch('auth/check');

    if (auth) {
      fetch();
    } else {
      if (route.path.startsWith('/auth/verifieren')) {
        router.push({ name: AUTH_LOGIN });
      }
    }
  });

  onUnmounted(() => {
    window.removeEventListener('focus', handleFocus);
  });

  watch(authenticated, () => {
    if (authenticated.value) fetch();
  });
};
