
import { computed, defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Draggable from 'vuedraggable';
import { useStore } from 'vuex';

import {
  SECTION_TYPES,
  WORKSPACE_KINDS,
  WORKSPACE_TYPES,
} from '@/config/enums';
import useValidation from '@/hooks/useValidation';
import { Resource, Workspace } from '@/services/api/modules/workspaces';
import { State } from '@/store';

import Item from './Item.vue';

type Event = {
  [key in 'moved' | 'added']: {
    element: Resource | Workspace;
    newIndex: number;
    oldIndex: number;
  };
};

export default defineComponent({
  props: {
    type: {
      type: String as () => SECTION_TYPES,
      required: true,
    },
  },

  components: {
    Item,
    Draggable,
  },

  setup(props) {
    const { t } = useI18n();
    const store = useStore<State>();
    const modal = ref();

    const displayTypes = [
      {
        label: t('common.directory'),
        value: 'workspaces',
      },
      {
        label:
          props.type === SECTION_TYPES.NEWS
            ? t('common.news-feed')
            : t('common.calendar'),
        value: 'resources',
      },
    ];

    const displayType = ref('workspaces');

    const workspaceType =
      props.type === SECTION_TYPES.CALENDAR
        ? [WORKSPACE_KINDS.CALENDARS, WORKSPACE_KINDS.CALENDAR]
        : [WORKSPACE_KINDS.COLUMN];

    const filtered = computed(() => {
      const items = store.getters['workspaces/getItems'] as Workspace[];

      return items
        .filter(
          (item) => workspaceType.includes(item.wsKind) && item.arrangeable,
        )
        .map((item) => ({
          ...item,
          resources: item.items.map((resource) => ({
            id: item.id,
            ...resource,
          })),
        }));
    });

    const workspaces = ref(filtered.value);
    watch(filtered, () => {
      workspaces.value = filtered.value;
    });

    const hide = () => modal.value?.hide();
    const show = () => modal.value?.show();

    const handleChangeWorkspace = (event: Event) => {
      if (event.moved) {
        const { newIndex, oldIndex = 0, element } = event.moved;
        const { id, wsType } = element as Workspace;

        const addOne = newIndex > oldIndex;
        const defaultOrder = addOne ? newIndex + 1 : newIndex;
        const sharedOrder = newIndex - 1;
        const order =
          wsType === WORKSPACE_TYPES.SHARED ? sharedOrder : defaultOrder;

        store.dispatch('workspaces/updateItem', {
          id,
          order,
        });
      }
    };

    const handleChangeResource = (event: Event, item: Workspace) => {
      const eventData = event.moved || event.added;

      if (eventData) {
        const { newIndex, oldIndex = 0, element } = eventData;
        const { resourceId } = element as Resource;
        const addOne = newIndex > oldIndex && event.moved;
        const order = addOne ? newIndex + 1 : newIndex;

        store.dispatch('workspaces/updateResourceItem', {
          workspaceId: event.added ? item.id : undefined,
          resourceId,
          order,
        });
      }
    };

    return {
      WORKSPACE_TYPES,
      SECTION_TYPES,
      modal,
      workspaces,
      displayTypes,
      displayType,
      hide,
      show,
      handleChangeWorkspace,
      handleChangeResource,
      ...useValidation(),
    };
  },
});
