
import { defineComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';

import { COLLECTION_TYPES, SECTION_TYPES } from '@/config/enums';
import { Resource, Workspace } from '@/services/api/modules/workspaces';
import { State } from '@/store';

import Delete from './Delete.vue';
import Edit from './Edit.vue';
import Empty from './Empty.vue';

function checkResource(v: unknown): v is Resource {
  return !!v && Object.prototype.hasOwnProperty.call(v, 'resourceId');
}

export default defineComponent({
  components: {
    Edit,
    Delete,
    Empty,
  },

  props: {
    item: {
      type: Object as () => Workspace | Resource,
      required: true,
    },
    type: {
      type: String as () => SECTION_TYPES,
      required: true,
    },
    empty: {
      type: Boolean,
      required: false,
      default: false,
    },
    draggable: {
      type: Boolean,
      required: false,
      default: false,
    },
    toggleable: {
      type: Boolean,
      required: false,
      default: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props) {
    const isResource = checkResource(props.item);
    const store = useStore<State>();
    const initVisible = isResource
      ? props.item.settings?.isVisible ?? true
      : true;
    const visible = ref(initVisible);
    const deleteModal = ref();
    const editModal = ref();
    const emptyModal = ref();

    const id = isResource ? props.item.resourceId : props.item.id;

    const resourceKind = isResource ? props.item.resourceKind : null;

    const idType = isResource
      ? COLLECTION_TYPES.RESOURCE
      : COLLECTION_TYPES.WORKSPACE;

    watch(visible, () => {
      if (idType === COLLECTION_TYPES.RESOURCE) {
        store.dispatch('workspaces/updateResourceItem', {
          resourceId: id,
          settings: { isVisible: visible.value },
        });
      }
    });

    const showEdit = () => {
      editModal.value?.show();
    };

    const showDelete = () => {
      if (idType === COLLECTION_TYPES.WORKSPACE && !props.empty) {
        emptyModal.value?.show();
      } else {
        deleteModal.value?.show();
      }
    };

    return {
      visible,
      id,
      resourceKind,
      idType,
      deleteModal,
      editModal,
      emptyModal,
      showEdit,
      showDelete,
    };
  },
});
