
import getYear from 'date-fns/fp/getYear/index';
import { computed, defineComponent, ref } from 'vue';

import { version } from '@/../package.json';
import { PRIVACY_POLICY } from '@/config/routes';
import useFeatures from '@/hooks/useFeatures';

import ChangePassword from '../components/ChangePassword.vue';
import MyDetails from '../components/MyDetails.vue';
import Notifications from '../components/Notifications.vue';
import System from '../components/System.vue';

export default defineComponent({
  components: {
    ChangePassword,
    MyDetails,
    Notifications,
    System,
  },

  setup() {
    const form = ref();
    const loading = ref();
    const notifications = ref();
    const system = ref();
    const myDetails = ref();
    const changePassword = ref();

    const year = computed(() => getYear(new Date()));

    const handleSave = () => {
      loading.value = true;

      const sections = [notifications, system, myDetails, changePassword];

      Promise.all(
        sections.map(async (section) => {
          await section.value.save();
        }),
      ).finally(() => {
        loading.value = false;
      });
    };

    return {
      version,
      PRIVACY_POLICY,
      form,
      loading,
      notifications,
      system,
      myDetails,
      changePassword,
      year,
      handleSave,
      ...useFeatures(),
    };
  },
});
