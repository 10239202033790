
import { computed, defineComponent, reactive, ref } from 'vue';
import { useQuery } from 'vue-query';

import api from '@/services/api';
import { Resource, Workspace } from '@/services/api/modules/workspaces';

export default defineComponent({
  props: {
    resource: {
      type: Object as () => Resource,
      required: true,
    },
    workspace: {
      type: Object as () => Workspace,
      required: true,
    },
    showSearch: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    selectedRegion: function () {
      this.fetchRegion();
    },
    showSearch: function () {
      if (!this.showSearch) {
        this.selectedRegion = {
          id: '0',
          text: 'Selecteer op regio',
          value: '0',
        };
      }
    },
  },
  emits: ['openSearch', 'handleSearches', 'resetField'],
  setup(props, { emit }) {
    const fetchRegions = async () => {
      const data = await api.sources.expertRegions(props.resource.resourceId);
      return data;
    };

    const queryKey = reactive([
      'regions',
      {
        id: props.resource.resourceId,
      },
    ]);

    const fetchRegion = async () => {
      if (selectedRegion.value.id != '') {
        if (selectedRegion.value.id != '0') {
          emit('openSearch');
          emit('handleSearches', {
            context: 'regions',
            itemId: props.resource.resourceId,
            searchId: selectedRegion.value.id,
          });
        } else {
          emit('resetField', 'regions');
        }
      } else {
        emit('resetField', 'regions');
      }
    };

    const { data } = useQuery(queryKey, fetchRegions);
    const options = computed(() => {
      if (data.value) {
        const newArrayObj = data.value?.items.map((item) => {
          return {
            id: item.id,
            text: item.title,
            value: item.id,
          };
        });
        newArrayObj.unshift({
          id: '0',
          text: 'Selecteer op regio',
          value: '0',
        });
        return newArrayObj;
      } else {
        return [];
      }
    });
    const selectedRegion = ref({
      id: '0',
      text: 'Selecteer op regio',
      value: '0',
    });

    return {
      options,
      selectedRegion,
      fetchRegion,
    };
  },
});
