import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row justify--end mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_button = _resolveComponent("va-button")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createBlock(_component_va_modal, {
    ref: "modal",
    stateful: true,
    title: _ctx.title,
    "hide-default-actions": true
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.confirm) + " ", 1),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_va_button, {
          color: "primary",
          class: "mr-2",
          flat: "",
          onClick: _ctx.hide
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('actions.cancel')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_va_button, {
          color: "primary",
          onClick: _ctx.handleDelete,
          loading: _ctx.loading
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('actions.delete')), 1)
          ]),
          _: 1
        }, 8, ["onClick", "loading"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}