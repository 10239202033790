import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "row justify--end mt-8" }
const _hoisted_2 = {
  key: 3,
  class: "mb-2"
}
const _hoisted_3 = { class: "row justify--end mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_button = _resolveComponent("va-button")!
  const _component_anp_message = _resolveComponent("anp-message")!
  const _component_anp_label = _resolveComponent("anp-label")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_anp_link = _resolveComponent("anp-link")!
  const _component_va_select = _resolveComponent("va-select")!
  const _component_va_alert = _resolveComponent("va-alert")!
  const _component_va_switch = _resolveComponent("va-switch")!
  const _component_anp_form = _resolveComponent("anp-form")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createBlock(_component_va_modal, {
    ref: "modal",
    stateful: true,
    title: 
      _ctx.query
        ? _ctx.$t('search.overwrite-search-query')
        : _ctx.$t('search.save-search-query')
    ,
    "hide-default-actions": true
  }, {
    default: _withCtx(() => [
      (_ctx.query)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('search.overwrite-search-query-confirm')) + " ", 1),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_va_button, {
                color: "primary",
                class: "mr-2",
                flat: "",
                onClick: _ctx.hide
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('actions.cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_va_button, {
                color: "primary",
                onClick: _ctx.handleSubmit,
                loading: _ctx.loading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('actions.overwrite')), 1)
                ]),
                _: 1
              }, 8, ["onClick", "loading"])
            ])
          ], 64))
        : (_openBlock(), _createBlock(_component_anp_form, {
            key: 1,
            ref: "form",
            onSubmit: _ctx.handleSubmit
          }, {
            default: _withCtx(() => [
              _createVNode(_component_anp_message, { message: _ctx.message }, null, 8, ["message"]),
              _createVNode(_component_anp_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.type === _ctx.SECTION_TYPES.NEWS
            ? _ctx.$t('news.news-feed-name')
            : _ctx.type === _ctx.SECTION_TYPES.SOURCE
            ? _ctx.$t('source.source-name')
            : _ctx.$t('calendar.calendar-name')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_va_input, {
                modelValue: _ctx.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                rules: [_ctx.required]
              }, null, 8, ["modelValue", "rules"]),
              _createVNode(_component_anp_label, null, {
                right: _withCtx(() => [
                  _createVNode(_component_anp_link, { onClick: _ctx.showAdd }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.type === _ctx.SECTION_TYPES.NEWS || _ctx.type === _ctx.SECTION_TYPES.SOURCE
                ? _ctx.$t('news.add-directory')
                : _ctx.$t('calendar.add-directory')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.directory')) + " ", 1)
                ]),
                _: 1
              }),
              (_ctx.workspace != null && _ctx.workspace.value != null)
                ? (_openBlock(), _createBlock(_component_va_select, {
                    key: 0,
                    modelValue: _ctx.workspace,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.workspace) = $event)),
                    options: _ctx.workspaceOptions
                  }, null, 8, ["modelValue", "options"]))
                : _createCommentVNode("", true),
              (_ctx.workspace != null && _ctx.workspace.value != null && _ctx.isSharedWorkspace)
                ? (_openBlock(), _createBlock(_component_va_alert, {
                    key: 1,
                    color: "info",
                    border: "left",
                    class: "mt-6"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.save-shared-notice')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.workspaceOptions == null || _ctx.workspaceOptions.length == 0)
                ? (_openBlock(), _createBlock(_component_va_alert, {
                    key: 2,
                    color: "info",
                    border: "left",
                    class: "mt-6"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.save-nomaps-notice')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_openBlock(), _createBlock(_resolveDynamicComponent(
          _ctx.type === _ctx.SECTION_TYPES.NEWS || _ctx.type === _ctx.SECTION_TYPES.SOURCE
            ? 'AddNewsDirectory'
            : 'AddCalendarDirectory'
        ), { ref: "addModal" }, null, 512)),
              (_ctx.workspace != null)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_va_switch, {
                      modelValue: _ctx.enabled,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.enabled) = $event)),
                      label: _ctx.$t('common.notifications'),
                      class: "mb-2",
                      "left-label": "",
                      size: "small"
                    }, null, 8, ["modelValue", "label"]),
                    (_ctx.enabled)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createVNode(_component_anp_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('settings.frequency')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_va_select, {
                            modelValue: _ctx.frequency,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.frequency) = $event)),
                            class: "mb-6",
                            options: _ctx.frequencyOptions
                          }, null, 8, ["modelValue", "options"])
                        ], 64))
                      : _createCommentVNode("", true),
                    (_ctx.enabled && _ctx.frequency.value == 1440)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createVNode(_component_anp_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('settings.send-time')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_va_select, {
                            modelValue: _ctx.time,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.time) = $event)),
                            class: "mb-6",
                            options: _ctx.timeOptions
                          }, null, 8, ["modelValue", "options"])
                        ], 64))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_va_button, {
                  color: "primary",
                  flat: "",
                  onClick: _ctx.hide
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('actions.cancel')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_va_button, {
                  color: "primary",
                  type: "submit",
                  loading: _ctx.loading,
                  disabled: _ctx.workspaceOptions == null || _ctx.workspaceOptions.length == 0
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('actions.save')), 1)
                  ]),
                  _: 1
                }, 8, ["loading", "disabled"])
              ])
            ]),
            _: 1
          }, 8, ["onSubmit"]))
    ]),
    _: 1
  }, 8, ["title"]))
}