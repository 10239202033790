import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row justify--end mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_anp_label = _resolveComponent("anp-label")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_anp_form = _resolveComponent("anp-form")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createBlock(_component_va_modal, {
    ref: "modal",
    stateful: true,
    title: _ctx.$t('clippings.mail-newsletter'),
    "hide-default-actions": true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_anp_form, {
        ref: "form",
        onSubmit: _ctx.handleSubmit
      }, {
        default: _withCtx(() => [
          _createVNode(_component_anp_label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('user.email')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_va_input, {
            modelValue: _ctx.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
            rules: [_ctx.required, _ctx.validEmail]
          }, null, 8, ["modelValue", "rules"]),
          _createVNode(_component_anp_label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.subject')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_va_input, {
            modelValue: _ctx.subject,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.subject) = $event)),
            rules: [_ctx.required]
          }, null, 8, ["modelValue", "rules"]),
          _createVNode(_component_anp_label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.message')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_va_input, {
            modelValue: _ctx.message,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.message) = $event)),
            type: "textarea",
            rules: [_ctx.required]
          }, null, 8, ["modelValue", "rules"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_va_button, {
              color: "primary",
              class: "mr-2",
              flat: "",
              onClick: _ctx.hide
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('actions.cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_va_button, {
              color: "primary",
              type: "submit",
              loading: _ctx.loading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('actions.send')), 1)
              ]),
              _: 1
            }, 8, ["loading"])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }, 8, ["title"]))
}