
import { computed, defineComponent, reactive, ref } from 'vue';
import { useQuery } from 'vue-query';

import api from '@/services/api';
import { Resource, Workspace } from '@/services/api/modules/workspaces';

export default defineComponent({
  props: {
    resource: {
      type: Object as () => Resource,
      required: true,
    },
    workspace: {
      type: Object as () => Workspace,
      required: true,
    },
    showSearch: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    selectedSection: function () {
      this.fetchSection();
    },
    showSearch: function () {
      if (!this.showSearch) {
        this.selectedSection = {
          id: '0',
          text: 'Selecteer op sectie',
          value: '0',
        };
      }
    },
  },
  emits: ['openSearch', 'handleSearches', 'closeSearch'],
  setup(props, { emit }) {
    const fetchSections = async () => {
      const data = await api.newslettercontent.sections(
        props.resource.resourceId,
      );
      return data;
    };

    const queryKey = reactive([
      'sections',
      {
        id: props.resource.resourceId,
      },
    ]);

    const fetchSection = async () => {
      if (selectedSection.value.id != '') {
        if (selectedSection.value.id != '0') {
          emit('openSearch');
          emit('handleSearches', {
            context: 'searches',
            itemId: props.resource.resourceId,
            searchId: selectedSection.value.id,
          });
        } else {
          emit('closeSearch');
        }
      }
    };

    const { data } = useQuery(queryKey, fetchSections);
    const options = computed(() => {
      if (data.value) {
        const newArrayObj = data.value?.items.map((item) => {
          return {
            id: item.id,
            text: item.title,
            value: item.id,
          };
        });
        newArrayObj.unshift({
          id: '0',
          text: 'Selecteer op sectie',
          value: '0',
        });
        return newArrayObj;
      } else {
        return [];
      }
    });
    const selectedSection = ref({
      id: '0',
      text: 'Selecteer op sectie',
      value: '0',
    });

    return {
      options,
      selectedSection,
      fetchSection,
    };
  },
});
