
import { computed, defineComponent, ref } from 'vue';

import { RESOURCE_KINDS, WORKSPACE_TYPES } from '@/config/enums';
import useFeatures from '@/hooks/useFeatures';
import EditQuery from '@/modules/search/modals/EditQuery.vue';
import { Resource, Workspace } from '@/services/api/modules/workspaces';

import ColumnDelete from '../modals/ColumnDelete.vue';
import ColumnMove from '../modals/ColumnMove.vue';

export default defineComponent({
  components: {
    EditQuery,
    ColumnDelete,
    ColumnMove,
  },

  props: {
    resource: {
      type: Object as () => Resource,
      required: true,
    },
    workspace: {
      type: Object as () => Workspace,
      required: true,
    },
  },

  emits: ['doneEdit'],

  setup(props, { emit }) {
    const opened = ref(false);
    const isEditable = computed(() => props.workspace.editable);
    const isQuery = computed(
      () => props.resource.resourceKind === RESOURCE_KINDS.QUERY,
    );

    const editModal = ref();
    const showEdit = () => editModal.value?.show();

    const editQueryModal = ref();
    const showEditQuery = () => editQueryModal.value?.show();

    const moveModal = ref();
    const showMove = () => moveModal.value?.show();

    const deleteModal = ref();
    const showDelete = () => deleteModal.value?.show();

    const handleDone = () => emit('doneEdit');

    return {
      WORKSPACE_TYPES,
      opened,
      isEditable,
      isQuery,
      editModal,
      showEdit,
      editQueryModal,
      showEditQuery,
      deleteModal,
      showDelete,
      moveModal,
      showMove,
      handleDone,
      ...useFeatures(),
    };
  },
});
