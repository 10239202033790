
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';

import { Article } from '@/services/api/modules/article.types';

export default defineComponent({
  props: {
    article: {
      type: Object as () => Article,
      required: true,
    },
  },

  setup(props) {
    const route = useRoute();

    const routeName = computed(() => route.name);
    const workspaceId = computed(() => route.params.id);
    const resourceId = computed(() => route.params.resourceId);

    const otherVersions = computed(() => {
      return props.article.otherVersions;
    });

    return {
      otherVersions,
      routeName,
      workspaceId,
      resourceId,
    };
  },
});
