import i18n from '@/plugins/i18n';

import { SETTINGS } from '../routes';

export default [
  {
    id: SETTINGS,
    title: i18n.global.t('common.settings'),
    to: { name: SETTINGS },
  },
];
