
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue';

import { DISPLAY_TYPES } from '@/config/enums';
import { SEARCH_ARTICLE } from '@/config/routes';
import { ArticleListItem } from '@/services/api/modules/article.types';

export default defineComponent({
  props: {
    items: {
      type: Array as () => ArticleListItem[],
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    square: {
      type: Boolean,
      required: true,
    },
    showDescription: {
      type: Boolean,
      required: true,
    },
    loadingMore: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['loadmore'],

  setup(props, { emit }) {
    const itemsIds = computed(() => props.items.map((item) => item.id));
    const root = ref();

    const scrollElement = computed(() => {
      return root.value.querySelector('.list-wrapper');
    });

    const handleScroll = () => {
      const { scrollTop, offsetHeight, scrollHeight } = scrollElement.value;
      const scrollDistance = scrollTop + offsetHeight;
      if (!props.loadingMore && scrollDistance > scrollHeight - 100) {
        emit('loadmore');
      }
    };

    onMounted(() => {
      scrollElement.value.addEventListener('scroll', handleScroll);
    });

    onBeforeUnmount(() => {
      scrollElement.value.removeEventListener('scroll', handleScroll);
    });

    return {
      itemsIds,
      root,
      SEARCH_ARTICLE,
      DISPLAY_TYPES,
      handleScroll,
    };
  },
});
