
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import { useInfiniteQuery } from 'vue-query';
import { useRoute } from 'vue-router';

import {
  ARTICLE_RELATIONSHIP,
  RELATED_KINDS,
  RESOURCE_KINDS,
} from '@/config/enums';
import api from '@/services/api';
import { ArticleRelatedItem } from '@/services/api/modules/article.types';
import { Resource } from '@/services/api/modules/workspaces';

export default defineComponent({
  computed: {
    ARTICLE_RELATIONSHIP() {
      return ARTICLE_RELATIONSHIP;
    },
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Object as () => ArticleRelatedItem[],
      required: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    type: {
      type: String as () => ARTICLE_RELATIONSHIP,
      required: false,
    },
    articleId: {
      type: String,
      required: false,
    },
    showTime: {
      type: Boolean,
      required: false,
    },
    resource: {
      type: Object as () => Resource,
      required: false,
    },
    vectorizer: {
      type: String,
      required: false,
    },
  },

  setup(props) {
    const route = useRoute();
    const count = ref(5);

    const routeName = computed(() => route.name);
    const workspaceId = computed(() => route.params.id);
    const resourceId = computed(() => route.params.resourceId);

    const apiModule = computed(() => {
      if (!props.resource) return api.search;

      switch (props.resource?.resourceKind) {
        case RESOURCE_KINDS.CALENDARS:
          return api.calendars;
        case RESOURCE_KINDS.QUERY:
          return api.queries;
        case RESOURCE_KINDS.NEWSLETTERCONTENT:
          return api.newslettercontent;
        case RESOURCE_KINDS.SOURCE:
        default:
          return api.sources;
      }
    });

    const formatDate = (date: Date) => {
      if (props.showTime) {
        return format(date, 'HH:mm:ss', { locale: nl });
      } else {
        return format(date, 'dd-MM-yyyy', { locale: nl });
      }
    };

    const getInitials = (fullName: string) => {
      return fullName
        .replace(/[^a-zA-Z- ]/g, '')
        .match(/\b\w/g)
        ?.join('');
    };

    const getRelatedId = (item: ArticleRelatedItem) => {
      if (props.resource?.resourceKind == RESOURCE_KINDS.NEWSLETTERCONTENT) {
        return props.articleId + '/related/' + item.id;
      } else {
        return item.id;
      }
    };

    const fetchRelated = ({ pageParam: lastItem = '' }) => {
      if (props.items) {
        return { items: props.items, totalCount: 0 };
      } else if (props.articleId) {
        return apiModule.value.articleRelated(
          props.resource?.resourceId as string,
          props.articleId,
          {
            count: 5,
            totalCount: true,
            relation: props.type,
            fromItem: lastItem,
            vectorizer: props.vectorizer,
            includeScore: props.vectorizer ? 1 : 0,
          },
        );
      }
    };

    const queryKey = reactive([
      'article-related',
      {
        count,
        type: props.type,
        id: props.articleId,
        vectorizer: props.vectorizer,
      },
    ]);

    const query = useInfiniteQuery(queryKey, fetchRelated, {
      getNextPageParam: (lastPage) => {
        const [lastItem] = lastPage?.items.slice(-1) || [];
        return lastItem?.id;
      },
    });

    const renderItems = computed(
      () =>
        props.items ||
        (query?.data.value?.pages
          .map((item) => item?.items)
          .flat()
          .filter(Boolean) as ArticleRelatedItem[]),
    );

    const totalCount = computed(
      () => props.total || query?.data.value?.pages[0]?.totalCount,
    );

    watch(
      () => props.articleId,
      () => {
        query.refetch.value();
      },
    );

    return {
      RELATED_KINDS,
      routeName,
      workspaceId,
      resourceId,
      formatDate,
      getInitials,
      renderItems,
      totalCount,
      getRelatedId,
      ...query,
    };
  },
});
