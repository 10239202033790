
import { computed, ref } from 'vue';
import { defineComponent } from 'vue';

import accountNavigation from '@/config/navigation/account';
import { Control } from '@/config/navigation/main';

interface Modals {
  [key: string]: {
    show(): void;
  };
}

export default defineComponent({
  props: {
    controls: {
      type: Object as () => Control[],
      required: true,
    },
  },

  setup(props) {
    const modals = ref<Modals>({});

    const toggleModal = (id: string) => () => {
      modals.value[id].show();
    };

    const items = computed(() =>
      props.controls.map((item) => ({
        click: item.modal ? toggleModal(item.id) : null,
        ...item,
      })),
    );

    return {
      modals,
      items,
      accountNavigation,
      toggleModal,
    };
  },
});
