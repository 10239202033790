<template>
  <component
    :is="$attrs.to ? 'router-link' : $attrs.href ? 'a' : 'anp-clickable'"
    class="link"
    v-bind="$attrs"
  >
    <slot />
  </component>
</template>

<style scoped>
.link {
  color: var(--va-primary);
  font-weight: 500;
}
</style>
