import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "mb-2"
}
const _hoisted_2 = {
  key: 2,
  class: "mb-2"
}
const _hoisted_3 = {
  key: 3,
  class: "row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_anp_skeleton = _resolveComponent("anp-skeleton")!
  const _component_va_list_item_section = _resolveComponent("va-list-item-section")!
  const _component_va_list_item = _resolveComponent("va-list-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (number) => {
    return _createVNode(_component_va_list_item, { key: number }, {
      default: _withCtx(() => [
        _createVNode(_component_va_list_item_section, null, {
          default: _withCtx(() => [
            (_ctx.date)
              ? (_openBlock(), _createBlock(_component_anp_skeleton, {
                  key: 0,
                  type: "date",
                  class: "mb-2"
                }))
              : _createCommentVNode("", true),
            (_ctx.heading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.random(1, 3), (number) => {
                    return (_openBlock(), _createBlock(_component_anp_skeleton, {
                      key: number,
                      type: "heading"
                    }))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (_ctx.text)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.random(3, 5), (number) => {
                    return (_openBlock(), _createBlock(_component_anp_skeleton, {
                      key: number,
                      type: "text"
                    }))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (_ctx.meta)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(1, (number) => {
                    return _createVNode(_component_anp_skeleton, {
                      key: number,
                      type: "meta"
                    })
                  }), 64))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024)
      ]),
      _: 2
    }, 1024)
  }), 64))
}