
import { computed, defineComponent, ref } from 'vue';

import { Article } from '@/services/api/modules/article.types';

import PopUp from './PopUp.vue';

export default defineComponent({
  props: {
    article: {
      type: Object as () => Article,
      required: true,
    },
  },
  components: {
    PopUp,
  },

  setup(props) {
    const saveModal = ref();
    const links = computed(() =>
      props.article.links.filter(
        (link) => link.kind === 'origin' || link.kind === 'related',
      ),
    );

    const anpLinks = computed(() =>
      props.article.links.filter((link) => link.kind === 'anp'),
    );

    const isMobile = computed(() => {
      if (screen.width < 760) {
        return true;
      } else {
        return false;
      }
    });
    const handleClick = () => saveModal.value?.show();
    return {
      links,
      anpLinks,
      handleClick,
      isMobile,
      saveModal,
    };
  },
});
