import { mapValues, omitBy } from 'lodash';

export default (values: {
  [key: string]: string | number | boolean | undefined;
}): string => {
  const params = new URLSearchParams(
    mapValues(
      omitBy(values, (value) => !value),
      (value) => String(value),
    ),
  );

  const string = params.toString();

  if (!string) return '';
  return `?${string}`;
};
