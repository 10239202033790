
import { groupBy } from 'lodash';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import { Source } from '@/services/api/modules/sources';
import { State } from '@/store';

export default defineComponent({
  props: {
    init: {
      type: Array as () => Source[],
      required: false,
    },
  },

  setup() {
    const store = useStore<State>();
    const source = computed(() => {
      return store.state.sources.items.filter((s) => {
        return s.articleKind === 'expert';
      });
    });
    const groups = groupBy(source.value, 'bundleTitle');
    const selection = ref();

    const getSelectedIds = () => {
      return selection.value?.getSelectedIds();
    };

    return {
      groups,
      selection,
      getSelectedIds,
    };
  },
});
