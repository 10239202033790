<template>
  <div class="label">
    <label>
      <slot />
    </label>

    <slot name="right" />
  </div>
</template>

<style scoped>
.label {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--va-charcoal);
  margin-bottom: 0.25rem;
  font-size: 0.75em;
  font-weight: 600;
}
</style>
