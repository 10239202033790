
import { defineComponent, ref } from 'vue';

import useFeatures from '@/hooks/useFeatures';

export default defineComponent({
  setup() {
    const clipLoading = ref(false);

    return {
      clipLoading,

      ...useFeatures(),
    };
  },
});
