import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_alert = _resolveComponent("va-alert")!
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_va_avatar = _resolveComponent("va-avatar")!
  const _component_va_list_item_section = _resolveComponent("va-list-item-section")!
  const _component_va_list_item_label = _resolveComponent("va-list-item-label")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_anp_popover = _resolveComponent("anp-popover")!
  const _component_va_checkbox = _resolveComponent("va-checkbox")!
  const _component_va_list_item = _resolveComponent("va-list-item")!
  const _component_va_list = _resolveComponent("va-list")!
  const _component_DeleteNewsletters = _resolveComponent("DeleteNewsletters")!
  const _component_MailNewsletter = _resolveComponent("MailNewsletter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showError)
      ? (_openBlock(), _createBlock(_component_va_alert, {
          key: 0,
          color: "danger",
          border: "left",
          class: "mb-6"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('clippings.no-newsletters-selected')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.items.length)
      ? (_openBlock(), _createBlock(_component_va_alert, {
          key: 1,
          color: "info",
          border: "left"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('clippings.no-newsletters')), 1)
          ]),
          _: 1
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createVNode(_component_va_list, { fit: "" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                return (_openBlock(), _createBlock(_component_va_list_item, {
                  key: item.id,
                  href: `${_ctx.baseUri}newsletters/${item.id}`,
                  target: "_blank",
                  rel: "nofollow noreferrer noopener"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_va_list_item_section, { avatar: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_va_avatar, { color: "primary" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_va_icon, {
                              name: 
                item.mimeType.split('/')[1] === 'pdf'
                  ? 'picture_as_pdf'
                  : 'code'
              
                            }, null, 8, ["name"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_va_list_item_section, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_va_list_item_label, { class: "mb-1" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_va_list_item_label, {
                          caption: "",
                          class: "-mb-1"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.mimeType.split('/')[1] === 'pdf'
                ? _ctx.$t('common.pdf-document')
                : _ctx.$t('common.html-document')), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_va_list_item_label, { caption: "" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.formatDate(item)), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_va_list_item_section, { icon: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_anp_popover, {
                          message: _ctx.$t('common.mail')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_va_button, {
                              icon: "mail",
                              color: "dark",
                              flat: "",
                              onClick: _withModifiers(($event: any) => (_ctx.showMail(item)), ["prevent"])
                            }, null, 8, ["onClick"])
                          ]),
                          _: 2
                        }, 1032, ["message"]),
                        _createVNode(_component_anp_popover, {
                          message: _ctx.$t('common.view')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_va_button, {
                              href: `${_ctx.baseUri}newsletters/${item.id}`,
                              target: "_blank",
                              rel: "nofollow noreferrer noopener",
                              icon: "visibility",
                              color: "dark",
                              flat: ""
                            }, null, 8, ["href"])
                          ]),
                          _: 2
                        }, 1032, ["message"]),
                        _createVNode(_component_va_checkbox, {
                          modelValue: _ctx.selected[item.id],
                          "onUpdate:modelValue": ($event: any) => ((_ctx.selected[item.id]) = $event),
                          class: "ml-2",
                          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["href"]))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_va_button, {
            class: "mt-8",
            color: "danger",
            block: "",
            outline: "",
            onClick: _ctx.showDelete
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('clippings.delete-newsletters')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ], 64)),
    _createVNode(_component_DeleteNewsletters, {
      ref: "deleteModal",
      newsletterIds: _ctx.selectedIds
    }, null, 8, ["newsletterIds"]),
    _createVNode(_component_MailNewsletter, {
      ref: "mailModal",
      newsletter: _ctx.newsletter
    }, null, 8, ["newsletter"])
  ], 64))
}