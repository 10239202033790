import { ActionContext } from 'vuex';

import { AUTH_LOGIN } from '@/config/routes';
import router from '@/plugins/router';
import api from '@/services/api';
import {
  ChangePassword,
  Cookie,
  Credentials,
  ForgotPassword,
  ResetPassword,
  StoreUser,
  User,
} from '@/services/api/modules/auth';
import { State as R } from '@/store/index';

export interface State {
  authenticated: boolean;
  cookie?: Cookie;
  user?: User;
}

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: undefined,
  },

  mutations: {
    setAuthenticated(state: State, authenticated: boolean): void {
      state.authenticated = authenticated;
    },

    setUser(state: State, user: User): void {
      state.user = user;
    },

    updateUser(state: State, user: User): void {
      state.user = { ...state.user, ...user };
    },

    reset(state: State): void {
      state.authenticated = false;
      state.user = undefined;
    },
  },

  actions: {
    async login(
      { commit }: ActionContext<State, R>,
      user?: Credentials,
    ): Promise<void> {
      const cookie = await api.auth.login(user);

      if (cookie) {
        commit('setAuthenticated', true);
      }
    },

    async google(): Promise<void> {
      const google = await api.auth.google();
      window.location.href = google.url;
    },

    async microsoft(): Promise<void> {
      const microsoft = await api.auth.microsoft();
      window.location.href = microsoft.url;
    },

    async logout({ commit }: ActionContext<State, R>): Promise<void> {
      await api.auth.logout();
      commit('reset');
      router.push({ name: AUTH_LOGIN });
    },

    forgotPassword(
      _: ActionContext<State, R>,
      item: ForgotPassword,
    ): Promise<void> {
      return api.auth.forgotPassword(item);
    },

    resetPassword(
      _: ActionContext<State, R>,
      item: ResetPassword,
    ): Promise<void> {
      return api.auth.resetPassword(item);
    },

    changePassword(
      _: ActionContext<State, R>,
      item: ChangePassword,
    ): Promise<void> {
      return api.auth.changePassword(item);
    },

    async check({ commit, state }: ActionContext<State, R>): Promise<boolean> {
      const authenticated = await api.auth
        .login()
        .then(() => true)
        .catch(() => false);

      if (authenticated) {
        if (!state.authenticated) {
          commit('setAuthenticated', true);
        }
      } else {
        commit('reset');
      }

      return authenticated;
    },

    async fetchUser({ commit }: ActionContext<State, R>): Promise<void> {
      const user = await api.auth.user();
      commit('setUser', user);
    },

    async updateUser(
      { commit }: ActionContext<State, R>,
      item: StoreUser,
    ): Promise<void> {
      await api.auth.updateUser(item);
      commit('updateUser', item);
    },
  },

  getters: {},
};
