import { createI18n } from 'vue-i18n';

import config from '@/config';
import en from '@/locales/en.json';
import nl from '@/locales/nl.json';

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: config.app.language,
  fallbackLocale: 'nl',
  availableLocales: ['en', 'nl'],
  messages: { en: en, nl: nl },
});
