import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3aa33689"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "links"
}
const _hoisted_2 = { class: "display-6" }
const _hoisted_3 = {
  key: 1,
  class: "links"
}
const _hoisted_4 = { class: "display-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_chip = _resolveComponent("va-chip")!
  const _component_PopUp = _resolveComponent("PopUp")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.anpLinks.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('common.anpLinks')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.anpLinks, (anpLink, index) => {
            return (_openBlock(), _createBlock(_component_va_chip, {
              key: index,
              outline: "",
              size: "small",
              icon: "link",
              href: anpLink.value,
              target: "_blank",
              rel: "nofollow noreferrer noopener"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(anpLink.title || _ctx.$t('common.' + anpLink.kind)), 1)
              ]),
              _: 2
            }, 1032, ["href"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.links.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('common.links')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              (link.title !== 'phone' || _ctx.isMobile)
                ? (_openBlock(), _createBlock(_component_va_chip, {
                    key: 0,
                    outline: "",
                    size: "small",
                    icon: "link",
                    href: link.value,
                    target: "_blank",
                    rel: "nofollow noreferrer noopener"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.' + link.kind)), 1)
                    ]),
                    _: 2
                  }, 1032, ["href"]))
                : (link.title === 'phone' && !_ctx.isMobile)
                  ? (_openBlock(), _createBlock(_component_va_chip, {
                      key: 1,
                      onClick: _ctx.handleClick,
                      outline: "",
                      size: "small",
                      icon: "link",
                      target: "_blank",
                      rel: "nofollow noreferrer noopener"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.' + link.title)), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true)
            ], 64))
          }), 128)),
          _createVNode(_component_PopUp, {
            ref: "saveModal",
            links: _ctx.links
          }, null, 8, ["links"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}