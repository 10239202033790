
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import { NEWSLETTER_FORMATS } from '@/config/enums';
import useValidation from '@/hooks/useValidation';

export default defineComponent({
  props: {
    clippingsIds: {
      type: Array as () => string[],
      required: false,
    },
  },

  emits: ['done'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();

    const typeOptions = ref([
      { text: t('common.pdf-document'), value: NEWSLETTER_FORMATS.PDF },
      { text: t('common.html-document'), value: NEWSLETTER_FORMATS.HTML },
    ]);

    const modal = ref();
    const form = ref();
    const loading = ref(false);
    const name = ref('');
    const type = ref(typeOptions.value[0]);

    const hide = () => modal.value?.hide();
    const show = () => modal.value?.show();

    const handleCancel = () => {
      emit('done');
      hide();
    };

    const handleSubmit = () => {
      loading.value = true;

      store
        .dispatch('newsletters/saveItem', {
          name: name.value,
          format: type.value.value,
          items: props.clippingsIds,
        })
        .then(() => {
          name.value = '';
          type.value = typeOptions.value[0];

          modal.value?.hide();
          emit('done');
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      typeOptions,
      modal,
      form,
      loading,
      name,
      type,
      hide,
      show,
      handleSubmit,
      handleCancel,
      ...useValidation(),
    };
  },
});
