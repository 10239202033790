import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_anp_column_item = _resolveComponent("anp-column-item")!
  const _component_anp_column = _resolveComponent("anp-column")!

  return (_ctx.articles)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["live-agenda-column", { hidden: _ctx.hidden }])
      }, [
        _createVNode(_component_anp_column, {
          title: _ctx.title,
          square: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.articles, (item) => {
              return (_openBlock(), _createBlock(_component_anp_column_item, {
                key: item.id,
                item: item,
                resourceId: _ctx.resource?.resourceId,
                articleRouteName: _ctx.NEWS_ARTICLE,
                articleIds: _ctx.articleIds,
                articles: _ctx.articles,
                showMeta: "",
                active: _ctx.articleId === item.id
              }, null, 8, ["item", "resourceId", "articleRouteName", "articleIds", "articles", "active"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["title"])
      ], 2))
    : _createCommentVNode("", true)
}