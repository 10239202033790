<template>
  <div class="wrapper px-6 py-4">
    <div class="container">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.container {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  padding: 1rem 0;
}
</style>
