<template>
  <va-card>
    <va-card-content>
      <div class="loader">
        <va-progress-circle size="large" :thickness="0.2" indeterminate />
      </div>
    </va-card-content>
  </va-card>
</template>

<style lang="scss" scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
