
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

import { SEARCH } from '@/config/routes';
import { ArticleMediaTopics } from '@/services/api/modules/article.types';

export default defineComponent({
  props: {
    items: Array as () => ArticleMediaTopics[],
  },

  setup(props) {
    const showMoreSources = ref(false);
    const router = useRouter();

    const handleClick = (tag: string) => {
      router.push({ name: SEARCH, params: { query: tag } });
    };

    return {
      showMoreSources,
      handleClick,
    };
  },
});
