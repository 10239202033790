import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_card_title = _resolveComponent("va-card-title")!
  const _component_anp_message = _resolveComponent("anp-message")!
  const _component_anp_label = _resolveComponent("anp-label")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_anp_form = _resolveComponent("anp-form")!
  const _component_va_card_content = _resolveComponent("va-card-content")!
  const _component_va_card = _resolveComponent("va-card")!

  return (_openBlock(), _createBlock(_component_va_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_va_card_title, null, {
        default: _withCtx(() => [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t('auth.forgot-password')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_va_card_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_anp_form, {
            ref: "form",
            onSubmit: _ctx.handleSubmit
          }, {
            default: _withCtx(() => [
              _createVNode(_component_anp_message, { message: _ctx.message }, null, 8, ["message"]),
              _createVNode(_component_anp_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('user.email')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_va_input, {
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                rules: [_ctx.required, _ctx.validEmail]
              }, null, 8, ["modelValue", "rules"]),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('auth.forgot-password-info')), 1),
              _createVNode(_component_va_button, {
                block: "",
                class: "mt-8",
                type: "submit",
                loading: _ctx.loading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('auth.send-email')), 1)
                ]),
                _: 1
              }, 8, ["loading"]),
              _createVNode(_component_va_button, {
                block: "",
                outline: "",
                class: "mt-2",
                to: { name: _ctx.AUTH_LOGIN }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('auth.back-to-login')), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _: 1
          }, 8, ["onSubmit"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}