import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_alert = _resolveComponent("va-alert")!

  return (_ctx.message)
    ? (_openBlock(), _createBlock(_component_va_alert, {
        key: 0,
        class: "message",
        color: _ctx.message.type,
        border: "left"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.message.text), 1)
        ]),
        _: 1
      }, 8, ["color"]))
    : _createCommentVNode("", true)
}