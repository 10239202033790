
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { NEWS_ARTICLE } from '@/config/routes';
import { ArticleListItem } from '@/services/api/modules/article.types';
import { Resource } from '@/services/api/modules/workspaces';

export default defineComponent({
  props: {
    resource: {
      type: Object as () => Resource,
      required: false,
    },
    articleId: {
      type: String,
      required: true,
    },
    hidden: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const articles = ref<ArticleListItem[]>(history.state.articles);
    const articleIds = ref<string[]>(history.state.articleIds);

    const title = computed(
      () => `${t('news.live-agenda')} "${props.resource?.title}"`,
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleState = (event: any) => {
      articles.value = event.state.articles;
      articleIds.value = event.state.articleIds;
    };

    onMounted(() => {
      window.addEventListener('popstate', handleState);

      setTimeout(() => {
        articles.value = history.state.articles;
        articleIds.value = history.state.articleIds;
      });
    });

    onUnmounted(() => {
      window.removeEventListener('popstate', handleState);
    });

    return {
      NEWS_ARTICLE,
      title,
      articles,
      articleIds,
    };
  },
});
