export default {
  app: {
    language: process.env.VUE_APP_LANGUAGE as string,
  },

  api: {
    uri: process.env.VUE_APP_API_URI as string,
    version: process.env.VUE_APP_API_VERSION as string,
    id: process.env.VUE_APP_API_ID as string,
  },

  interval: {
    liveAgenda: 120000,
  },
};
