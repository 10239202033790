
import { mapValues, pickBy } from 'lodash';
import { computed, defineComponent, ref, watch } from 'vue';

interface Item {
  id: string;
  title: string;
}

interface Groups {
  [key: string]: Item[];
}

interface SelectedGroups {
  [key: string]: {
    selected: boolean;
    totalCount: number;
    selectedCount: number;
  };
}

export default defineComponent({
  props: {
    groups: {
      type: Object as () => Groups,
      required: true,
    },
    init: {
      type: Array as () => string[],
      required: false,
    },
    initValue: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const items = computed(() => Object.values(props.groups).flat());
    const initSelectedItems = computed(() =>
      items.value.reduce<{ [key: string]: boolean }>(
        (accumulator, item) => ({
          ...accumulator,
          [item.id]: props.init
            ? props.init.includes(item.id)
            : props.initValue,
        }),
        {},
      ),
    );
    const openState = ref(mapValues(props.groups, () => false));
    const selectedItems = ref(initSelectedItems.value);
    const selectAll = ref(props.initValue);

    const selectedArray = computed(() => Object.values(selectedItems.value));
    const totalCount = computed(() => selectedArray.value.length);
    const selectedCount = computed(
      () => selectedArray.value.filter(Boolean).length,
    );

    const selectedGroups = computed(() =>
      Object.entries(props.groups).reduce<SelectedGroups>(
        (accumulator, [id, items]) => ({
          ...accumulator,
          [id]: {
            selected: items.every((item) => selectedItems.value[item.id]),
            totalCount: items.length,
            selectedCount: items.filter((item) => selectedItems.value[item.id])
              .length,
          },
        }),
        {},
      ),
    );

    const groupToggleIcons = computed(() =>
      mapValues(openState.value, (value: boolean) =>
        value ? 'expand_less' : 'expand_more',
      ),
    );

    const handleToggleGroup = (title: string) => {
      openState.value[title] = !openState.value[title];
    };

    const handleChangeSelectAll = (value: boolean) => {
      Object.keys(initSelectedItems.value).forEach((id) => {
        selectedItems.value[id] = value;
      });
    };

    const handleChangeGroup = (title: string, value: boolean) => {
      const bundleIds = props.groups[title].map((item) => item.id);

      bundleIds.forEach((id) => {
        selectedItems.value[id] = value;
      });
    };

    const handleChangeItem = (id: string, value: boolean) => {
      selectedItems.value[id] = value;
    };

    const getSelectedIds = () => {
      return Object.keys(pickBy(selectedItems.value, Boolean));
    };

    watch(selectedItems.value, () => {
      selectAll.value = Object.values(selectedItems.value).every(Boolean);
    });

    watch(initSelectedItems, () => {
      selectedItems.value = initSelectedItems.value;
    });

    watch(
      () => props.groups,
      () => {
        openState.value = mapValues(props.groups, () => false);
      },
    );

    return {
      openState,
      selectAll,
      selectedGroups,
      selectedItems,
      totalCount,
      selectedCount,
      groupToggleIcons,
      handleToggleGroup,
      handleChangeSelectAll,
      handleChangeGroup,
      handleChangeItem,
      getSelectedIds,
    };
  },
});
