import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row justify--end mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_button = _resolveComponent("va-button")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createBlock(_component_va_modal, {
    ref: "modal",
    stateful: true,
    title: 
      _ctx.type === _ctx.SECTION_TYPES.CALENDAR
        ? _ctx.$t('calendar.delete-calendar')
        : _ctx.$t('news.delete-directory')
    ,
    "hide-default-actions": true
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.type === _ctx.SECTION_TYPES.CALENDAR
        ? _ctx.$t('calendar.delete-directory-empty')
        : _ctx.$t('news.delete-directory-empty')) + " ", 1),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_va_button, {
          color: "primary",
          onClick: _ctx.hide
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('actions.close')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}