
import { isEqual } from 'lodash';
import { computed, defineComponent, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import useFeatures from '@/hooks/useFeatures';
import useMessage from '@/hooks/useMessage';
import useValidation from '@/hooks/useValidation';
import { State } from '@/store';

export default defineComponent({
  methods: { useFeatures },
  setup() {
    const store = useStore<State>();
    const { t, locale, availableLocales } = useI18n({});
    const { message, setMessage, clearMessage } = useMessage();

    const languageOptions = [
      { text: t('search.language-options.nl'), value: 'nl' },
      { text: t('search.language-options.en'), value: 'en' },
      { text: t('search.language-options.de'), value: 'de' },
      { text: t('search.language-options.fr'), value: 'fr' },
      { text: t('search.language-options.es'), value: 'es' },
      { text: t('search.language-options.it'), value: 'it' },
      { text: t('search.language-options.sv'), value: 'sv' },
      { text: t('search.language-options.da'), value: 'da' },
      { text: t('search.language-options.fi'), value: 'fi' },
      { text: t('search.language-options.nb'), value: 'nb' },
    ];

    const switchLocale = (lang: string) => {
      locale.value = lang;
    };

    const getEnglish = () => {
      for (var i = 0; i < languageOptions.length; i++) {
        var item = languageOptions[i];
        if (item.value == 'en') {
          return item;
        }
      }
    };
    const initialLanguages = () => {
      let l = store.state.auth.user?.notTranslatedLanguages;
      let retval: { text: string; value: string }[] = [];
      if (l == null || l.length == 0) {
        let eng = getEnglish();
        if (eng != null) {
          retval.push(eng);
        }
        return retval;
      } else {
        languageOptions.forEach(function (item) {
          if (l != null && l.includes(item.value)) {
            retval.push(item);
          }
        });
        return retval;
      }
    };

    const langValues = () => {
      if (values.notTranslatedLanguages.length == 0) {
        return [];
      } else {
        let retval: string[] = [];
        values.notTranslatedLanguages.forEach(function (item) {
          retval.push(item.value);
        });
        return retval;
      }
    };

    const user = computed(() => ({
      firstName: store.state.auth.user?.firstName,
      lastName: store.state.auth.user?.lastName,
      gender: store.state.auth.user?.gender || 'm',
      email: store.state.auth.user?.email,
      mobilePhone: store.state.auth.user?.mobilePhone,
      preferedLanguage: store.state.auth.user?.preferedLanguage,
      notTranslatedLanguages: store.state.auth.user?.notTranslatedLanguages,
    }));

    const values = reactive({
      firstName: user.value?.firstName,
      lastName: user.value?.lastName,
      gender: user.value?.gender,
      email: user.value?.email,
      mobilePhone: user.value?.mobilePhone,
      preferedLanguage: user.value?.preferedLanguage,
      notTranslatedLanguages: initialLanguages(),
    });

    const valuesForSubmit = (values: any) => {
      return {
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        email: values.email,
        mobilePhone: values.mobilePhone,
        preferedLanguage: values.preferedLanguage,
        notTranslatedLanguages: langValues(),
      };
    };

    const save = () => {
      clearMessage();
      if (!isEqual(values, user.value)) {
        if (store.state.auth.user) {
          store.state.auth.user.notTranslatedLanguages = langValues();
        }
        return store
          .dispatch('auth/updateUser', valuesForSubmit(values))
          .then(setMessage('auth.update-user-success'))
          .catch(setMessage());
      }
    };

    return {
      languageOptions,
      message,
      values,
      save,
      switchLocale,
      ...useValidation(),
    };
  },
});
