import { createApp } from 'vue';

import App from './App.vue';
import Clickable from './components/ui/Clickable.vue';
import Column from './components/ui/Column.vue';
import ColumnAllLoaded from './components/ui/ColumnAllLoaded.vue';
import ColumnEmpty from './components/ui/ColumnEmpty.vue';
import ColumnItem from './components/ui/ColumnItem.vue';
import ColumnLoader from './components/ui/ColumnLoader.vue';
import ColumnSkeleton from './components/ui/ColumnSkeleton.vue';
import Container from './components/ui/Container.vue';
import Form from './components/ui/Form.vue';
import Label from './components/ui/Label.vue';
import Link from './components/ui/Link.vue';
import Message from './components/ui/Message.vue';
import Placeholder from './components/ui/Placeholder.vue';
import Popover from './components/ui/Popover.vue';
import Selection from './components/ui/Selection.vue';
import Skeleton from './components/ui/Skeleton.vue';
import i18n from './plugins/i18n';
import router from './plugins/router';
import vuestic, { config } from './plugins/vuestic';
import store from './store';

const app = createApp(App);

app.use(i18n);
app.use(router);
app.use(vuestic, config);
app.use(store);

app.component('anp-clickable', Clickable);
app.component('anp-column', Column);
app.component('anp-column-all-loaded', ColumnAllLoaded);
app.component('anp-column-empty', ColumnEmpty);
app.component('anp-column-item', ColumnItem);
app.component('anp-column-skeleton', ColumnSkeleton);
app.component('anp-column-loader', ColumnLoader);
app.component('anp-container', Container);
app.component('anp-form', Form);
app.component('anp-label', Label);
app.component('anp-link', Link);
app.component('anp-message', Message);
app.component('anp-popover', Popover);
app.component('anp-placeholder', Placeholder);
app.component('anp-selection', Selection);
app.component('anp-skeleton', Skeleton);

app.mount('#app');
