
import { defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import useValidation from '@/hooks/useValidation';
import { Newsletter } from '@/services/api/modules/newsletters';

export default defineComponent({
  props: {
    newsletter: {
      type: Object as () => Newsletter,
      required: false,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const modal = ref();
    const form = ref();
    const loading = ref(false);
    const email = ref('');
    const subject = ref(`${t('common.anp-app')}: ${props.newsletter?.name}`);
    const message = ref(t('clippings.mail-newsletter-message'));

    watch(
      () => props.newsletter,
      () => {
        subject.value = `${t('common.anp-app')}: ${props.newsletter?.name}`;
      },
    );

    const hide = () => modal.value?.hide();
    const show = () => modal.value?.show();

    const handleSubmit = () => {
      loading.value = true;

      store
        .dispatch('newsletters/sendMail', {
          id: props.newsletter?.id,
          email: email.value,
          subject: subject.value,
          message: message.value,
        })
        .then(() => {
          modal.value?.hide();
          email.value = '';
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      modal,
      form,
      email,
      loading,
      subject,
      message,
      hide,
      show,
      handleSubmit,
      ...useValidation(),
    };
  },
});
