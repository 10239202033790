
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'text',
      validator: (value: string) =>
        ['date', 'heading', 'text', 'meta'].includes(value),
    },
  },
});
