
import { defineComponent, ref } from 'vue';

import { SECTION_TYPES } from '@/config/enums';
import useMessage from '@/hooks/useMessage';
import useValidation from '@/hooks/useValidation';

export default defineComponent({
  props: {
    links: {
      required: true,
    },
  },

  emits: ['done'],

  setup() {
    const modal = ref();
    const addModal = ref();
    const { message } = useMessage();
    const hide = () => modal.value?.hide();
    const show = () => modal.value?.show();

    return {
      SECTION_TYPES,
      modal,
      addModal,
      message,
      hide,
      show,
      ...useValidation(),
    };
  },
});
