
import { computed } from 'vue';
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import accountNavigation from '@/config/navigation/account';
import topNavigation from '@/config/navigation/top';

export default defineComponent({
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    showToggle: {
      type: Boolean,
      required: true,
    },
    toggleOpen: {
      type: Function,
      required: true,
    },
  },

  setup(props) {
    const route = useRoute();
    const store = useStore();
    const authenticated = computed(() => store.state.auth.authenticated);

    const icon = computed(() =>
      props.open ? 'chevron_left' : 'chevron_right',
    );

    const topItems = computed(() =>
      topNavigation.map((item) => ({
        ...item,
        render: item.render(route.path),
      })),
    );

    const handleLogout = () => store.dispatch('auth/logout');

    return {
      icon,
      topItems,
      accountNavigation,
      handleLogout,
      authenticated,
    };
  },
});
