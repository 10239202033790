import Base from '@/components/layouts/Base.vue';
import { CALENDAR, CALENDAR_ARTICLE } from '@/config/routes';

import Article from './views/Article.vue';
import Index from './views/Index.vue';

export default [
  {
    path: '/kalender',
    component: Base,
    children: [
      {
        name: CALENDAR,
        path: ':resourceId?',
        component: Index,
        children: [
          {
            name: CALENDAR_ARTICLE,
            path: 'artikel/:articleId',
            component: Article,
          },
        ],
      },
    ],
  },
];
